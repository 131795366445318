import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../services/user-data.service';
import { PatientService } from '../../services/patient.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FillerNavService } from 'src/app/services/filler-nav.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  affiliate;
  fillerNav;

  constructor(protected userDataService: UserDataService,
    protected patientService: PatientService,
    protected authService: AuthService,
    protected router: Router,
    protected fillerNavService:FillerNavService) {
      this.fillerNav = this.fillerNavService.getFiller();
  }

  ngOnInit() {
    this.userDataService.getPatient().then(patient => {
      this.affiliate = patient;
      this.patientService.familyGroup(patient.familyGroup).then((familyGroup)=> {
        this.affiliate.members = familyGroup.members;
      });
    });
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/validation']);
  }
}
