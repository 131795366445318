import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationDataService } from '../../services/registration-data.service';
import { AuthService } from '../../services/auth.service';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import { UserDataService } from '../../services/user-data.service';
import { TermsAndConditionsService } from '../../services/terms-and-conditions.service';
import * as _ from 'lodash';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { LocalizationService } from 'src/app/services/localization.service';
import { AffiliatesService } from 'src/app/services/affiliates.service';
import { ModalComponent } from '../modal/modal.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-registration-second-step',
  templateUrl: './registration-second-step.component.html',
  styleUrls: ['./registration-second-step.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-ar'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class RegistrationSecondStepComponent implements OnInit {

  name;
  userEmail;
  birthdate;
  phone;
  gender;
  affiliate;
  input;
  isValidNumber = true;
  isAutologin = false;
  logoHeader
  providerName = "Llamando al Doctor"
  phoneInitialCountry = 'ar'
  showModal = false;
  modalText = "";

  constructor(protected router: Router,
    protected authService: AuthService,
    protected registrationDataService: RegistrationDataService,
    protected userDataService: UserDataService,
    protected termsService:TermsAndConditionsService,
    protected affiliateService:AffiliatesService,
    private adapter: DateAdapter<any>,
    private dialog: MatDialog,
    protected localizationService:LocalizationService) {
      this.adapter.setLocale(this.localizationService.getLocale());
     }

  ngOnInit() {

    this.phoneInitialCountry = this.getInitialCountry();

    var registrationData = this.registrationDataService.getAffiliate();
    if (!_.isEmpty(registrationData))
    {
      this.affiliate = registrationData;
      this.logoHeader = this.affiliate.provider.header;
      this.providerName = this.affiliate.provider.name;
      this.setComponentData(this.affiliate);
    }
    else
    {
      this.userDataService.getPatient().then((patient)=>{
        if(patient)
        {
          this.logoHeader = patient.provider.header;
          this.providerName = patient.provider.name;
          this.affiliate = patient;
          this.setComponentData(this.affiliate);
          if(patient.validator = "tokenValidator")
            this.isAutologin = true;
        } else {
          this.router.navigate(['']);
        }
      });
    }
  }

  continue() {

    if(this.isAutologin)
    {
      let dataToUpdate = {}
      
      if(this.userEmail && this.affiliate.email != this.userEmail) dataToUpdate['email'] = this.userEmail;
      if(this.birthdate && this.getBirthDate(this.affiliate) != this.birthdate) dataToUpdate['birthDate'] = moment(this.birthdate).unix();
      if(this.phone && this.getPhone(this.affiliate) != this.phone) dataToUpdate['mobilePhone'] = this.phone;
      if(this.gender && this.affiliate.gender != this.gender) dataToUpdate['gender'] = this.gender;

      this.affiliate.date = this.affiliate.birthdate;
      this.affiliateService.update(this.affiliate.id,dataToUpdate).then((patient)=>{
        this.redirect()
      })
      .catch((err)=>{
        if(err.status == 400 && err.type == "existingObject")
        {
          this.dialog.open(ModalComponent, {
            data: {
              text: "El email ingresado ya está en uso. Por favor, pruebe uno distinto para continuar.",
              textHeader: "Error"
            }
          });
        }
      })
    }
    else if(!this.isAutologin && this.affiliate.hasLoggedIn){

      let dataToUpdate = {}

      if(this.userEmail && this.affiliate.email != this.userEmail) dataToUpdate['email'] = this.userEmail;
      if(this.birthdate && this.getBirthDate(this.affiliate) != this.birthdate) dataToUpdate['birthDate'] = moment(this.birthdate).unix();
      if(this.phone && this.getPhone(this.affiliate) != this.phone) dataToUpdate['mobilePhone'] = this.phone;
      if(this.gender && this.affiliate.gender != this.gender) dataToUpdate['gender'] = this.gender;

      this.affiliateService.update(this.affiliate.id,dataToUpdate).then((patient)=>{
        this.redirect()
      })
      .catch((err)=>{
        console.log(err)
      })
    }
    else
    {
      if(this.name) this.affiliate.name = this.name
      if(this.userEmail) this.affiliate.email = this.userEmail;
      if(this.gender) this.affiliate.gender = this.gender;
      if(this.birthdate) this.setBirthDate(this.affiliate, this.birthdate);
      if(this.phone) this.setPhone(this.affiliate, this.phone);

      this.affiliate.providerId = this.affiliate.provider.id;

      this.registrationDataService.setAffiliate(this.affiliate);

      this.registrationDataService.saveAffiliateDataInLocalStorage();
      this.authService.signup(this.affiliate);
    }
  }

  redirect(){
    if(this.termsService.hasUserAccepted())
    {
      if(this.userDataService.hasPermissions())
        this.router.navigate(['/warning']);
      else
        this.router.navigate(['/ask-permissions']);
      }
    else
    {
      this.router.navigate(['/terms-and-conditions']);
    }
  }

  getNumber(e) {
    this.phone = e;
  }

  hasError(event) {
    this.isValidNumber = this.input.intlTelInput("isValidNumber");
  }

  telInputObject(event) {
    this.input = event;
  }

  setComponentData(affiliate) {
    if(affiliate.email){
      this.userEmail = affiliate.email;
    }
    var birthDate = this.getBirthDate(this.affiliate);
    if(birthDate) {
      var birthDateFromMoment = moment.unix(birthDate);
      var birthdateTimeZoned = momentTimeZone(birthDateFromMoment).tz("Europe/Paris");
      this.birthdate = birthdateTimeZoned;
    }
    if(affiliate.gender) {
      this.gender = affiliate.gender;
    }
   if(this.getPhone(this.affiliate)) {
     this.phone = affiliate.phone != undefined ? affiliate.phone : affiliate.mobilePhone //phone setter
   }
  }

  getBirthDate(affiliate){
    return (affiliate.birthDate || affiliate.birthdate);
  }

  getPhone(affiliate){
    return (affiliate.mobilePhone || affiliate.phone);
  }

  setBirthDate(affiliate, birthDate){
    if (affiliate.birthdate){
      affiliate.birthDate = moment(birthDate).unix();
    } else {
      affiliate.birthDate = moment(birthDate).unix();
    }
  }

  setPhone(affiliate, mobilePhone){
    if (affiliate.phone){
      affiliate.phone = mobilePhone;
    } else {
      affiliate.mobilePhone = mobilePhone;
    }
  }

  isValidEmail(email){
    if(!email) return false
    var re = /^[a-zA-ZñÑ0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-ZñÑ0-9-]+(?:\.[a-zA-ZñÑ0-9-]+)*$/;
    return re.test(String(email).toLowerCase());
  }

  getInitialCountry(){
    switch (this.localizationService.getLocale()) {
      case 'es-co':
        return 'co';
      case 'pt':
      case 'pt-br':
        return 'br';
      case 'en':
      case 'en-us':
        return 'us'
      default:
        return 'ar';
    } 
  }

}