import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Affiliate } from '../models/affiliate.models';

@Injectable({
  providedIn: 'root'
})
export class AffiliatesService {

  url = '/affiliate';

  constructor(protected requestService: RequestService) {  }

  getOne(dni:string,urlQueryParams:any): Promise<any> {
    var options={
      method:"GET",
      urlQueryParams:urlQueryParams
    }
    return this.requestService.makeRequest(options,this.url+'/'+dni)
    .then((obj) => {
      return obj.json() as Affiliate;
    })
  }

  update(id, affiliate){
    let options={
      method:"PUT",
      body:JSON.stringify(affiliate)
    }
    return this.requestService.makeRequest(options,this.url+'/'+id)
    .then((obj) => {
      return obj.json() as Affiliate;
    })
    .catch((err)=>{
      return Promise.reject(err._body)
    })
  }

  sendVerificationEmail(body){
    let options={
      method:"PUT",
      body:JSON.stringify(body)
    }
    return this.requestService.makeRequest(options,this.url+'/sendVerificationEmail')
    .then((obj) => {
      return obj.json()
    })
  }

  changePassword(body){
    let options={
      method:"PUT",
      body:JSON.stringify(body)
    }
    return this.requestService.makeRequest(options,this.url+'/changePassword')
    .then((obj) => {
      return obj.json()
    })
  }

  backToFirstStep(body){
    let options={
      method:"PUT",
      body:JSON.stringify(body)
    }
    return this.requestService.makeRequest(options,this.url+'/backToFirstStep')
    .then((obj) => {
      return obj.json()
    })
  }
}
