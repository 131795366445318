import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  textHeader:string;
  text:string;
  textHtml:string;

  constructor(protected localizationService:LocalizationService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.textHeader = this.data.textHeader
    this.text = this.data.text;
    if(this.data.textHtmlKey){
      var text = this.localizationService.getString(this.data.textHtmlKey)
      text = text.replace("__PATIENT_NAME__", this.data.patientName);
      text = text.replace("__PATIENT_EMAIL__", this.data.patientEmail);
      this.textHtml = text;
    }
  }

  close(){
    this.dialogRef.close();
  }
}
