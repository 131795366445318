import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-recall',
  templateUrl: './recall.component.html',
  styleUrls: ['./recall.component.css']
})
export class RecallComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RecallComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  retry(): void {
    this.dialogRef.close();
  }

  callAnother(): void {
    this.dialogRef.close({another:true});
  }

  cancel(): void {
    this.dialogRef.close({close:true});
  }
}
