import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getLocaleCurrencyName } from '@angular/common';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  
  translations:any;
  locale:string;
  defaultLocale:string = 'es-ar';

  supportedLocales = ['es-ar', 'es', 'es-co', 'pt-br', 'pt', 'en', 'en-us'];

  constructor(private http: HttpClient) { }

  use(locale: string): Promise<{}> {
    locale = Array.isArray(locale)? this.getSupportedLocaleFromArray(locale): locale;
    if(!_.isEmpty(this.getLocaleFromStorage())) {
      this.locale = this.getLocaleFromStorage();
    } else {
      if(this.supportedLocales.includes(locale)) {
        this.locale = locale;
        this.setLocaleInStorage(locale)
      } else {
        console.log(locale + " not supported, using default: " + this.defaultLocale);
        this.locale = this.defaultLocale;
      }
    }
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${this.locale}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.translations = Object.assign({}, translation || {});
          resolve(this.translations);
        },
        error => {
          this.translations = {};
          resolve(this.translations);
        }
      );
    });
  }

  getString(key:string):string {
    return this.translations[key];
  }

  getLocale():string{
    return this.locale;
  }

  setLocaleInStorage(locale) {
    sessionStorage.setItem('locale', locale);
  }

  getLocaleFromStorage() {
    return sessionStorage.getItem('locale');
  }
  getSupportedLocaleFromArray(locales){
    var i = 0;
    while(i<locales.length && locale==undefined) {
      if(this.supportedLocales.includes(locales[i].toLowerCase())) {
        var locale = locales[i].toLowerCase()
      }
    i++;
    }
    return locale;
  }

  getSpecialtyNameLocalized(specialty){
    switch (this.getLocale()) {
      case "pt":
      case "pt-br":
        return specialty.localizedName['pt-br'];
      case "en":
      case "en-us":
        return specialty.localizedName['en-us'];
      default:
        return specialty.localizedName['es-ar'];
    }
  }

  getHeaderLocale(): string {

    //We send only language headers thar are accepted by the API
    //That is because we use sails i18n to translate messages, to see documentation, visit https://0.12.sailsjs.com/documentation/concepts/internationalization
    switch (this.locale) {
      case 'pt-br':
      case 'pt':
        return 'pt-BR';
      case 'es-co':
        return 'es-CO';
      case 'en':
      case 'en-us':
        return 'en-US'
      default:
        return 'es-AR';
    }
  }
}
