import { Patient } from './patient.models';

export class FamilyGroup {
    members:Array<Patient>;


    public getMembers():Array<Patient> {
        return this.members;
    }
}
