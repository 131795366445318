import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import { UserDataService } from '../../services/user-data.service';
import { CallByPatientDataService } from '../../services/call-by-patient-data.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  mobileQuery: MediaQueryList;
  @Input() fillerNav;

  logoHeader = "assets/logo_header_common.png";
  hasAppointment = false;

  private _mobileQueryListener: () => void;


  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, protected userDataService:UserDataService, public callByPatientData:CallByPatientDataService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    userDataService.getPatient().then(patient => {
      this.logoHeader = patient.provider.header;
    });
    this.hasAppointment = userDataService.hasAppointment()
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
  }

  redirectToFaqs() {
    var win = window.open(environment.USER_FAQS_URL);
    win.focus();
  }
}
