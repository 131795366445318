import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { ResponseContentType } from '@angular/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  url = '/provider';
  tcUrl = '/tc';

  constructor(protected requestService: RequestService) {  }

  getTCUrl(id:string): any{
    return environment.SERVER_URL+this.url+"/"+id+this.tcUrl;
  }
}
