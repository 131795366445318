import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';

@Component({
  selector: 'app-not-allowed-navigator',
  templateUrl: './not-allowed-navigator.component.html',
  styleUrls: ['./not-allowed-navigator.component.css']
})
export class NotAllowedNavigatorComponent implements OnInit {

  os;
  message;

  iosMessage = "Estimado usuario, para poder hacer uso de esta funcionalidad, es necesario que utilice el navegador Safari."
  androidMessage = "Estimado usuario, para poder hacer uso de esta funcionalidad, es necesario que utilice el navegador Google Chrome. Si no tiene el navegador, lo puede descargar desde el Play Store."

  androidRedirectUrl = "https://play.google.com/store/apps/details?id=com.android.chrome"

  constructor(public dialogRef: MatDialogRef<NotAllowedNavigatorComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.os =_.clone(this.data.os);
    this.message = this.os == "ios" ? this.iosMessage : this.androidMessage;
  }

  redirect():void {
    window.location.href = this.androidRedirectUrl;
  }

  openInChrome() {
    window.location.href = 'googlechrome://navigate?url='+window.location.href;
  }
}
