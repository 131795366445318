import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegistrationDataService {

  constructor() { }

  private affiliate:any = {}

  // Get the affiliate data
  getAffiliate(): any {
    var aff = {...this.affiliate}
    return aff;
  }

  // Set the affiliate data from db.
  setAffiliate(affiliate: any) {
    this.affiliate = affiliate;
  }

  // Get the affiliate data from localStorage
  getAffiliateDataFromLocalStorage(): any {
    var affiliate = {
      provider_id: localStorage.getItem('provider'),
      validator: localStorage.getItem('validator'),
      documentNumber: localStorage.getItem('documentNumber'),
      phone: localStorage.getItem('phone'),
      gender: localStorage.getItem('gender'),
      date: localStorage.getItem('date'),
      name: localStorage.getItem('name')
    }
    return affiliate;
  }

  //Save the affiliate in localStorage.
  saveAffiliateDataInLocalStorage() {
    localStorage.setItem('provider', this.affiliate.providerId);
    localStorage.setItem('validator', this.affiliate.validator);
    localStorage.setItem('documentNumber', this.affiliate.documentNumber);
    localStorage.setItem('phone', this.affiliate.mobilePhone || this.affiliate.phone);
    localStorage.setItem('gender', this.affiliate.gender);
    localStorage.setItem('date', this.affiliate.birthDate || this.affiliate.birthdate);
    localStorage.setItem('hasLoggedIn', this.affiliate.hasLoggedIn);
    localStorage.setItem('name', this.affiliate.name);
  }

  //Remove the affiliate data from localStorage.
  removeAffiliateDataFromLocalStorage() {
    localStorage.removeItem('provider');
    localStorage.removeItem('validator');
    localStorage.removeItem('documentNumber');
    localStorage.removeItem('phone');
    localStorage.removeItem('gender');
    localStorage.removeItem('date');
    localStorage.removeItem('hasLoggedIn');
  }
}
