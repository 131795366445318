import { Component, OnInit } from '@angular/core';
import { AffiliatesService } from '../../services/affiliates.service';
import { MatDialog } from '@angular/material';
import { NoAffiliateComponent } from '../../dialogs/no-affiliate/no-affiliate.component';
import { UserDataService } from '../../services/user-data.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TermsAndConditionsService } from '../../services/terms-and-conditions.service';
import { RegistrationDataService } from '../../services/registration-data.service';
import { environment } from '../../../environments/environment';
import { LocalizationService } from 'src/app/services/localization.service';
import { FormControl, FormGroup, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

/** Error when invalid control is dirty, or submitted. */
export class ValidationErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || isSubmitted));
  }
}

@Component({
  selector: 'app-patient-validation',
  templateUrl: './patient-validation.component.html',
  styleUrls: ['./patient-validation.component.scss']
})
export class PatientValidationComponent implements OnInit {

  helpUrl:string;
  logo:string;
  covidUrl:string;
  validationData:FormGroup
  matcher = new ValidationErrorStateMatcher();

  constructor(protected affiliatesService: AffiliatesService,
    protected userDataService: UserDataService,
    protected registrationDataService: RegistrationDataService,
    protected router: Router,
    protected authService: AuthService,
    protected termsService: TermsAndConditionsService,
    protected localizationService:LocalizationService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.logo = this.getLogo();
    this.helpUrl = environment.REFERENCE_HELP_URL;
    this.covidUrl = this.getUrl();

    this.validationData = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.email]),
      documentNumber: new FormControl('', [Validators.required]),
      validator: new FormControl('', [Validators.required])
    });
  }

  onSubmit() {
    if(this.validationData.valid)
    {
      var body= {
        email: this.validationData.controls.email.value,
        documentNumber: this.validationData.controls.documentNumber.value,
        validator: this.validationData.controls.validator.value
      }

      return this.affiliatesService.getOne( this.validationData.controls.documentNumber.value ,body).then((affiliate)=>{
        affiliate.email = this.validationData.controls.email.value;
        this.userDataService.setLoggedIn(affiliate.hasLoggedIn);
        this.registrationDataService.setAffiliate(affiliate);
        
        if(affiliate && affiliate.hasLoggedIn)
        {
          this.userDataService.setPatient(affiliate);

          if(this.authService.isAuthenticated() && this.termsService.hasUserAccepted())
          {
            this.router.navigate(['/speciality-selection']);
          }
          else if(this.authService.isAuthenticated() && !this.termsService.hasUserAccepted())
          {
            this.router.navigate(['/terms-and-conditions']);
          }
          else if(!this.authService.isAuthenticated())
          {
            this.authService.login(affiliate.email);
          }
        }
        else if(affiliate && !affiliate.hasLoggedIn)
        {
          this.router.navigate(['/registration-first']);
        }

      }).catch((error)=>{
        if(error._body.status == 404){
          this.openNoAffiliateDialog(error._body.message);
        }
      });
    }
  }

  openNoAffiliateDialog(message): void {
    const dialogRef = this.dialog.open(NoAffiliateComponent, {
      data: {message:message}
    });
    dialogRef.afterClosed().subscribe(editedUser => {});
  }

  getLogo(): any {
    switch (this.localizationService.getLocale()) {
      case "pt":
      case "pt-br":
        return "assets/logo-pt.png";
      case "en":
      case "en-us":
        return "assets/logo-en.png"  
      default:
        return "assets/logo.png";
    }
  }

  getUrl() {
    switch (this.localizationService.getLocale()) {
      case "en":
      case "en-us":
      return "https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public"

      default:
      return "https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public"
    }
  }
}
