import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'epoch'
})
export class EpochPipe implements PipeTransform {

  transform(timeInEpoch: any, format?: string): any {
    return moment.unix(timeInEpoch).utc().format(format?format:'DD/MM/YYYY');
  }

}
