import { Injectable } from '@angular/core';
import * as amplitude from '../../../node_modules/amplitude-js'
import { environment } from '../../environments/environment.local'

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  key: string = environment.API_KEY

  constructor() {
    amplitude.getInstance().init(this.key);
   }

  public showEvent(event: string, eventProperties?: Object){
    amplitude.getInstance().logEvent(event, eventProperties);
  }

  public setUser(id: string) {
    amplitude.getInstance().setUserId(id);
  }

}