import { Component, OnInit } from '@angular/core';
import { DoctorService } from '../../services/doctor.service';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Doctor } from '../../models/doctor.models';
import { CallByPatientDataService } from '../../services/call-by-patient-data.service';
import { Router } from '@angular/router';
import { MedicalHistoryService } from '../../services/medical-history.service';
import { FillerNavService } from 'src/app/services/filler-nav.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { AmplitudeService } from '../../services/amplitude.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  fillerNav;
  doctors = []
  doctorsLimit = 10;
  starsRate = [0,0,0,0,0]
  results: any[] = [];
  queryField: FormControl = new FormControl();

  constructor(protected router: Router,
    protected doctorService: DoctorService,
    protected callByPatientDataService: CallByPatientDataService,
    protected medicalHistory:MedicalHistoryService,
    protected localizationService:LocalizationService,
    protected fillerNavService:FillerNavService,
    public amplitudeService: AmplitudeService) {
      this.fillerNav = this.fillerNavService.getFiller();
  }

  ngOnInit() {
    this.getLimitedDoctors(0);

    this.queryField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(queryField => {
        this.doctorService.searchDoctors(queryField,this.doctorsLimit)
        .subscribe(response => {
          var doctors = response.json() as Doctor[]
          doctors.forEach(doctor => {
            doctor['rateArray'] = this.getDoctorRate(doctor);
          });
          this.doctors = doctors;
        })
      });
  }

  getLimitedDoctors(skip){
    this.doctorService.searchDoctors('',this.doctorsLimit,skip)
    .subscribe(response => {
      var doctors = response.json() as Doctor[]
      doctors.forEach(doctor => {
        doctor['rateArray'] = this.getDoctorRate(doctor);
      });
      var doctorsFiltered = doctors.filter(doctor => (doctor.available && doctor.canCall && doctor.hasDevice))

      this.doctors = this.doctors.concat(doctorsFiltered)
      if(doctors.length < this.doctorsLimit)
      {
        return this.doctors;
      }
      this.getLimitedDoctors(skip+this.doctorsLimit)
    })
  }

  getSpecialtyLocalized(specialty){
    return this.localizationService.getSpecialtyNameLocalized(specialty);
  }

  getDoctorRate(doctor){

    var reputation = doctor.reputation;
    var rateArray = []
    var hasDecimal = (reputation - Math.floor(reputation)) !== 0 ? true : false;

    for (let i = 0; i < 5; i++) {
      if(i < Math.floor(reputation))
      {
        rateArray.push("full")
      }
      else if(i == Math.floor(reputation) && hasDecimal)
      {
        rateArray.push("half")
      }
      else
      {
        rateArray.push("empty")
      }
    }
    return rateArray;
  }

  onClickDoctor(doctor){
    this.callByPatientDataService.doctorToCall = doctor;
    this.amplitudeService.showEvent('paciente_selecciono_medico', {
      doctor: doctor.firstName + ' '  + doctor.lastName,
     });
    if(!this.callByPatientDataService.motive || this.medicalHistory.getSelectedHistories().length == 0)
    {
      this.router.navigate(['/patient-reason']);
    }
    else
    {
      this.router.navigate(['/calling']);
    }
  }

  isDoctorAvailable(doctor:Doctor){
    return (!doctor.available || !doctor.canCall || !doctor.hasDevice) ? false : true
  }
}
