import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';

import { Howl } from 'howler';
import * as moment from 'moment';
import * as _ from 'lodash';

import { DoctorService } from '../../services/doctor.service';
import { CallByPatientDataService } from '../../services/call-by-patient-data.service';
import { UserDataService } from '../../services/user-data.service';
import { MedicalHistoryService } from '../../services/medical-history.service';
import { SpecialitiesService } from '../../services/specialities.service';
import { AmplitudeService } from '../../services/amplitude.service';
import { CallService } from '../../services/call.service';
import { Speciality } from '../../models/speciality.models';
import { Patient } from '../../models/patient.models';
import { Call } from '../../models/call.models';

import { TimeoutMessageComponent } from '../../dialogs/timeout-message/timeout-message.component';
import { NotAvailableMessageComponent } from '../../dialogs/not-available-message/not-available-message.component';
import { RecallComponent } from '../../dialogs/recall/recall.component';

import { environment } from '../../../environments/environment';
import { throwIfEmpty } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';

import { DeviceDetectorService } from 'ngx-device-detector';
import { FillerNavService } from 'src/app/services/filler-nav.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { AppointmentTimeComponent } from 'src/app/dialogs/appointment-time/appointment-time.component';
import { MessageComponent } from 'src/app/dialogs/message/message.component';

@Component({
  selector: 'app-calling',
  templateUrl: './calling.component.html',
  styleUrls: ['./calling.component.scss']
})
export class CallingComponent implements OnInit {

  @ViewChild('inCallFrame') iframe: ElementRef;

  fillerNav;

  speciality:any;

  sound:Howl;
  doctorsAvailable:string[] = [];

  doctorsIterator;
  timeoutHandler;
  timeoutMilliseconds:number;

  patient:Patient;
  currentCall:Call;
  records:string[];
  motive:string;
  doctor: any;

  inCall:boolean = false;
  calling:boolean = false;
  canceled:boolean = false;

  publisher: OT.Publisher;
  publishing: Boolean;

  video:boolean = true;
  mic:boolean = true;

  startedAt;
  elapsed;
  callTimeHandler;
  validated:boolean = false;

  alreadyHangingUp:boolean = false;
  static hangUpSignal = "ON_HANG_UP";
  static hangUpSignalType = "notification";
  disconnecting: boolean = false;
  mustHangUp: boolean = false;
  elapsedSeconds: number;
  maxDuration: number;

  isEndless: boolean = false;
  appointmentAvailable: boolean;
  doctorToCall;

  iframeUrl: string = "assets/inCallFrame/index.html"
  
  soundLoaded:boolean = false;
  mediaStreamAvailable:boolean = false;
  
  noDoctorTimeoutHandler;
  errorTimeoutHandler;
  checkAppointmentStatusTimeout;

  waitingTime;
  connectionLost = false;

  appointmentEndTime;

  constructor(
              protected router: Router,
              protected doctorService:DoctorService, 
              protected callByPatientData:CallByPatientDataService,
              protected userData:UserDataService, 
              protected medicalHistory:MedicalHistoryService,
              protected callService:CallService,
              protected specialitiesService:SpecialitiesService,
              protected authService: AuthService,
              protected fillerNavService:FillerNavService,
              protected localizationService:LocalizationService,
              protected appointmentService:AppointmentService,
              private deviceService: DeviceDetectorService,
              public dialog: MatDialog,
              public amplitudeService: AmplitudeService) {
    
    this.sound = new Howl({
      src: ['assets/waiting.mp3'],
      loop: true
    });
    this.fillerNav = this.fillerNavService.getFiller();
    
  }

  ngOnInit() {
    var self = this;

    if(this.userData.hasAppointment()) {
      this.appointmentAvailable = false;
      this.checkAppointmentStatus()
    }

    if(!this.callByPatientData.doctorToCall)
    {
      this.isEndless = true;
      this.speciality = this.callByPatientData.speciality==undefined? this.specialitiesService.getById('0') : this.specialitiesService.getById(String(this.callByPatientData.speciality));
    }
    else
    {
      this.doctorToCall = this.callByPatientData.doctorToCall
      this.speciality = this.doctorToCall.specialties[0];
    }

    if(this.speciality.localizedName)
      this.speciality.localized = this.localizationService.getSpecialtyNameLocalized(this.speciality);

    this.sound.on("load",function(){
      self.soundLoaded =true;
    })

    if(this.sound.state() == 'loaded')
    {
      this.soundLoaded =true;
    }

    var constraints = { audio: true, video: true };
    navigator.mediaDevices.getUserMedia(constraints)
    .then(function(mediaStream) {
      self.mediaStreamAvailable = mediaStream.active;
      var tracks = mediaStream.getTracks();
      tracks.forEach(track => {
        track.stop();
      });
    })
    .catch(function(err) { console.log(err.name + ": " + err.message); });

    setInterval( () => {
      if (this.mustHangUp==true) this.disconnect();
    }, 500);
  }

  call() {
    if(this.userData.hasAppointment() && this.appointmentService.isExpired(this.appointmentEndTime))
    {
      this.appointmentService.setEndStatus("timeout")
      this.router.navigate(['/appointment-end']);
    }
    else
    {
      this.canceled = false;

      this.sound.play();
      this.calling = true;
      this.callByPatientData.callingOrInCall = true;

      this.doctorsIterator = this.doctorsAvailable[Symbol.iterator]();
      this.patient = this.callByPatientData.caller;
      this.records = this.medicalHistory.getSelectedHistories();
      this.motive = this.callByPatientData.motive;

      this.amplitudeService.showEvent('paciente_llamando', { provider: this.patient.provider.name });

      if(!this.isEndless){
        this.timeoutMilliseconds = this.userData.getCallTimeoutMilliseconds();
        this.callWithDoctor()
      } else {
        this.timeoutMilliseconds = this.userData.getInfiniteCallTimeoutMilliseconds();
        this.endlessCall();
      }
    }
  }

  private checkAppointmentStatus(){
    this.checkAppointmentStatusTimeout = undefined
    this.appointmentService.available(this.userData.getAppointment())
      .then((response)=>{
        if(response.appointmentStatus == "ready")
        {
          this.onAppointment();
        } else {
          if(response.data.ready_in >= 1)
          {
            var appointmentTimerDialog = this.dialog.open(AppointmentTimeComponent, { data: {countdownTime: response.data.ready_in},disableClose: true });
            appointmentTimerDialog.afterClosed().subscribe(()=>{
              this.checkAppointmentStatus();
            })
          }
          else
          {
            this.checkAppointmentStatusTimeout = setTimeout(() => {
              this.checkAppointmentStatus();
            }, 1000);
          }
        }
      })
      .catch((err)=>{
        let message;
        if(err.appointmentStatus == "ended")
          message = "El turno ya finalizó."
        this.dialog.open(MessageComponent, { data: {title: "¡Error!", message: message }, disableClose: true });
      })
  }

  private onAppointment(){
    this.appointmentService.get(this.userData.getAppointment()).then((appointment)=>{
      this.isEndless = false;
      this.doctorToCall = appointment.doctor
      this.specialitiesService.getOne(this.doctorToCall.mainSpecialty).then((specialty)=>{
        this.speciality = specialty
        if(this.speciality.localizedName)
          this.speciality.localized = this.localizationService.getSpecialtyNameLocalized(this.speciality);
      });
      
      this.appointmentAvailable = true;
      this.appointmentEndTime = appointment.endDate
    })
    .catch((err)=>{
      this.dialog.open(MessageComponent, { data: {title: "¡Error!", message: err }, disableClose: true });
      console.log("error",err);
    })
  }

  private fetchMoreDoctors() {
    this.doctorService.getBySpeciality(this.speciality.id).then((doctors:string[]) => {
      if(_.isEmpty(doctors)){
        console.log('No doctors available. Wait 10s and try again');
        var self = this;
        this.noDoctorTimeoutHandler = setTimeout( () => { self.fetchMoreDoctors(); }, 10 * 1000 );
      } else {
        this.doctorsAvailable = doctors;
        this.doctorsIterator = this.doctorsAvailable[Symbol.iterator]();
        this.endlessCall();
      }
    });
  }

  private waitAndTryAgain(callBySpecialty?) {
    var self = this;
    this.iframe.nativeElement.src = this.iframeUrl;
    this.iframe.nativeElement.onload = function() {
      var innerSelf = self;
      if(callBySpecialty===false)
        self.errorTimeoutHandler = setTimeout( () => innerSelf.createCall(innerSelf.doctorToCall, innerSelf.patient, innerSelf.records, innerSelf.motive), 30 * 1000 );
      else
        self.errorTimeoutHandler = setTimeout( () => innerSelf.createCallBySpecialty(innerSelf.speciality.realId, innerSelf.patient, innerSelf.records, innerSelf.motive), 30 * 1000 );
    }
  }

  private endlessCall() {
    var patient:Patient = this.patient;
    var records:string[] = this.records;
    var motive:string = this.motive;
    var self = this;
    
    this.iframe.nativeElement.src = this.iframeUrl;
    this.iframe.nativeElement.onload = function() {
      window.onmessage = function(e){
          var data = JSON.parse(e.data);
              if(data.hangup)
              {
                  self.hangup();
              }
              if(data.inCall)
              {
                  self.callInitiated();
              }
              if(data.connectionLost)
              {
                  self.connectionLost = true;
                  self.hangup();
              }
          };

      self.createCallBySpecialty(self.speciality.realId, patient, records, motive);
    }
  }

  private createCallBySpecialty(specialityId, patient, records, motive){
    var self = this;
    
    this.waitingTime = this.getWaitingTime()

    if(this.canceled) {
      this.cancel();
    } else {
    this.callService.createCallBySpecialty(specialityId, patient, records, motive).then((call:Call) => {
      if(call!=undefined) {
        this.currentCall = call;
        this.callByPatientData.call = call;
        this.doctor = call.doctor;

        this.amplitudeService.showEvent('llamada_creada_por_especialidad', {
          doctor: this.doctor.firstName + ' '  + this.doctor.lastName,
          callId: this.currentCall.id
         });

        if(!call.openTokApiKey)
        {
          call.openTokApiKey = environment.OPENTOK_API_KEY;
        }
        var data = {
          call: call
        }

        this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(data), '*');
        
        this.setCallingTimeout(call);
      }
    }).catch(error => {
      if(!this.canceled){
        this.errorTimeoutHandler = setTimeout(() => self.createCallBySpecialty(specialityId, patient, records, motive), 30 * 1000);
        this.amplitudeService.showEvent("error_llamada_por_especialidad", {
          status: error.status,
          message: error._body.message
        });
      } else if(this.canceled){
        console.log('is end less and user canceled' );
      }
    });
    }
  }

  private callWithDoctor() {
    var patient:Patient = this.patient;
    var records:string[] = this.records;
    var motive:string = this.motive;

    var doctor = this.doctorToCall

    this.createCall(doctor, patient, records, motive);
  }
  
  private createCallCb(doctor, patient, records, motive){
    var self = this

    window.onmessage = function(e){
      var data = JSON.parse(e.data);
      if(data.hangup)
      {
        self.hangup();
      }
      if(data.inCall)
      {
        self.callInitiated();
      }
      if(data.connectionLost)
      {
        self.connectionLost = true;
        self.hangup();
      }
    };

    let appointment = undefined
    if(this.userData.hasAppointment()) appointment = this.userData.getAppointment()

    this.callService.createCall(doctor, patient, records, motive, appointment).then((call:Call) => {
      if(this.canceled) {
        this.cancel();
      } else if(call!=undefined) {
        this.currentCall = call;
        this.callByPatientData.call = call;
        this.doctor = doctor;
        this.amplitudeService.showEvent("llamada_creada_por_nombre", {
          doctor: this.doctor.firstName + ' '  + this.doctor.lastName,
          callId: this.currentCall.id
        });

        if(!call.openTokApiKey)
        {
          call.openTokApiKey = environment.OPENTOK_API_KEY;
        }
        var data = {
          call: call
        }

        this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify(data), '*');
        
        this.setCallingTimeout(call);
      }
    }).catch(error => {
      if(error._body.status == '400') {
        if(this.userData.hasAppointment() && !this.appointmentService.isExpired(this.appointmentEndTime))
        {
          this.waitAndTryAgain(false);
        }
        else if(this.userData.hasAppointment())
        {
          let eventData = {appointmentId: appointment}
          if(this.doctor && this.currentCall)
          {
            eventData['doctor'] = this.doctor.firstName + ' '  + this.doctor.lastName
            eventData['callId'] = this.currentCall.id
          }
          this.amplitudeService.showEvent("doctor_no_disponible_por_turno", eventData);
          this.callWithDoctorNotAvailable();
        }
        else
        {
          let eventData = {doctor: this.doctorToCall.firstName + ' '  + this.doctorToCall.lastName}
          if(this.currentCall)
            eventData['callId'] = this.currentCall.id

          this.amplitudeService.showEvent("doctor_no_disponible", eventData);
          this.callWithDoctorNotAvailable();
        }
      }
    });
  }

  private createCall(doctor, patient, records, motive){
    //reload iframe
    var self = this
    this.iframe.nativeElement.src = this.iframeUrl;
    this.iframe.nativeElement.onload = function() {
      self.createCallCb(doctor, patient, records, motive);
    }
  }

  private switchToEndlessCall(){
    this.isEndless = true;
    this.doctorService.getBySpeciality(this.speciality.id).then((doctors:string[]) => {
      if(_.isEmpty(doctors)){
        this.endlessCallDoctorsNotAvailable()
      } else {
      this.doctorsAvailable = doctors;
      this.call()
      }
    });
  }

  private setCallingTimeout(call:Call) {
    this.timeoutHandler = setTimeout( () => {
      this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify({disconnect:true}), '*');

      this.callService.timeoutCall(call.id).then(_ => {
        this.timeoutEnd();
      }).catch((error) => {
        console.log("error en set calling timeout")
      });
    }, this.timeoutMilliseconds);
  }

  private timeoutEnd(){
    if(this.isEndless)
      this.waitAndTryAgain();
    else if(this.userData.hasAppointment() && !this.appointmentService.isExpired(this.appointmentEndTime))
      this.createCall(this.doctorToCall, this.patient, this.records, this.motive);
    else
      this.callWithDoctorTimeout();
  }

  private stopTimeout() {
    clearTimeout(this.timeoutHandler);
    clearTimeout(this.noDoctorTimeoutHandler);
    clearTimeout(this.errorTimeoutHandler);
    this.noDoctorTimeoutHandler = undefined;
    this.timeoutHandler = undefined;
    this.errorTimeoutHandler = undefined;
  }

  private callWithDoctorTimeout() {
    this.cancel();
    const dialogRef = this.dialog.open(TimeoutMessageComponent, {
      data: {isAppointment: this.userData.hasAppointment()},
    });
    dialogRef.afterClosed().subscribe(messageClosed => {
      if(!this.userData.hasAppointment())
      {
        const dialogRef = this.dialog.open(RecallComponent, {
          data: {},
        });
        dialogRef.afterClosed().subscribe(messageClosed => {
          if(!messageClosed) return
          if(messageClosed.another)
          {
            this.callByPatientData.doctorToCall = undefined
            this.doctorToCall = undefined
            this.switchToEndlessCall()
          }
          if(messageClosed.close)
          {
            this.router.navigate(['/speciality-selection']);
          }
        });
      }
      else
      {
        this.appointmentService.setEndStatus("timeout")
        this.router.navigate(['/appointment-end']);
      }
    });
  }

  private callWithDoctorNotAvailable() {
    this.cancel();
    const dialogRef = this.dialog.open(NotAvailableMessageComponent, {
      data: {isAppointment: this.userData.hasAppointment()},
    });
    dialogRef.afterClosed().subscribe(messageClosed => {
      if(!this.userData.hasAppointment())
      {
        const dialogRef = this.dialog.open(RecallComponent, {
          data: {},
        });
        dialogRef.afterClosed().subscribe(messageClosed => {
          if(!messageClosed) return
          if(messageClosed.another)
          {
            this.amplitudeService.showEvent("cambio_a_llamada_por_especialidad");
            this.callByPatientData.doctorToCall = undefined
            this.doctorToCall = undefined
            this.switchToEndlessCall()
          }
          if(messageClosed.close)
          {
            this.router.navigate(['/speciality-selection']);
          }
        });
      }
      else
      {
        this.appointmentService.setEndStatus("notAvailableDoctor")
        this.router.navigate(['/appointment-end']);
      }
    });
  }

  private endlessCallDoctorsNotAvailable() {
    this.cancel()
    const dialogRef = this.dialog.open(NotAvailableMessageComponent, {
      data: {isEndless: true},
    });

    dialogRef.afterClosed().subscribe(messageClosed => {
          this.router.navigate(['/speciality-selection']);
      });
  }

  private getWaitingTime(){
    let maxWaitingTime = 8;
    let minWaitingTime = 3;
    return Math.floor(Math.random() * (maxWaitingTime - minWaitingTime)) + minWaitingTime;
  }
  
  cancel(isClicked?: boolean) {
    isClicked ? this.amplitudeService.showEvent('llamada_desconectada_por_usuario') : this.amplitudeService.showEvent('llamada_desconectada_por_timeout');
    this.canceled = true;
    if(this.currentCall) {
      this.callService.cancel(this.currentCall.id).then( () => {
        this.currentCall = undefined;
      }).catch(e => {
        this.currentCall = undefined;
        this.stopTimeout();
        this.sound.stop();
        this.calling = false;
        this.callByPatientData.callingOrInCall = false;
      });
    }

    this.stopTimeout();
    this.sound.stop();
    this.calling = false;
    this.callByPatientData.callingOrInCall = false;
  } 

  private callInitiated() {
    this.stopTimeout();
    
    this.inCall = true;
    this.sound.stop();
    this.calling = false;

    this.startedAt = moment();
    this.callTimeHandler = setInterval( () => {
      this.elapsed = moment().hour(0).minute(0).second(moment().diff(this.startedAt, 's')).format('m:ss');
      this.elapsedSeconds = moment().diff(this.startedAt, 's');
      this.maxDuration = (this.currentCall.maxDuration / 1000 || environment.DEFAULT_CALL_TIMEOUT)
      if(this.elapsedSeconds >= this.maxDuration)
        this.hangup();

      if((this.elapsedSeconds >= environment.MIN_SECONDS_FOR_VALID_CALL) && !this.validated ) //VALIDATE CALL
        this.validateCall();
    }, 500);
  }

  validateCall(){
    this.validated = true
    this.callService.validateCall(this.currentCall.id).then(() => { this.validated = true; })
    .catch(function(err) { this.validated = false; console.log(err.name + ": " + err.message); });
  }

  hangup() {
    if (this.alreadyHangingUp) return;
    this.alreadyHangingUp = true;

    this.disconnect();
  }

  disconnect() {
    if(this.disconnecting) return;
    this.amplitudeService.showEvent('llamada_desconectada');

    this.disconnecting = true;
    this.alreadyHangingUp = true;
    
    clearInterval(this.callTimeHandler);
    this.callTimeHandler = undefined;

    this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify({disconnect:true}), '*');

    this.callService.endCallPatient(this.currentCall.id, moment().diff(this.startedAt, 's'), this.connectionLost).then(() => {
      this.router.navigate(['/patient-rate-call']);
    }).catch(e => {
      this.router.navigate(['/patient-rate-call']);
    });
  }
}
