import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-not-available-message',
  templateUrl: './not-available-message.component.html',
  styleUrls: ['./not-available-message.component.css']
})
export class NotAvailableMessageComponent implements OnInit {
  isEndless;
  isAppointment;

  constructor(public dialogRef: MatDialogRef<NotAvailableMessageComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.isEndless = data.isEndless
    this.isAppointment = data.isAppointment
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

}
