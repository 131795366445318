import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalComponent } from './components/modal/modal.component';
import { Router } from '@angular/router';
import { UserDataService } from './services/user-data.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TokenExpiredService {

  constructor(private dialog: MatDialog,
    private router: Router) { }

  showModal() {
    let errorMessage = "Expiró la sesión. Para realizar otra consulta, ingresá desde el mismo lugar donde accediste la última vez, ya sea desde la plataforma de tu proveedor o completando los datos que se piden a continuación."
    var beforeAppointmentUnix = Number(localStorage.getItem('not_before'))
    let beforeAppointmentDate = moment.unix(beforeAppointmentUnix)

    if(localStorage.getItem('appointment') != undefined && moment().isBefore(beforeAppointmentDate))
      errorMessage = "El turno todavia no comenzó. Por favor, volvé a ingresar cuando sea la hora."

    const dialogRef = this.dialog.open(ModalComponent, {
      data: {
        text: errorMessage,
        textHeader: "¡Atención!"
      }
    });
    dialogRef.afterClosed().subscribe(resp => {
      localStorage.removeItem('appointment')
      this.router.navigate([''])
    });
  }
}
