import { Injectable } from '@angular/core';
import { Affiliate } from '../models/affiliate.models';
import { Patient } from '../models/patient.models';
import { stringify } from 'querystring';
import { PatientService } from './patient.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { ModalComponent } from '../components/modal/modal.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor(protected patientService:PatientService,
    public auth: AuthService,
    private dialog: MatDialog,
    private router: Router) { }

  private userData:any = {}

  // Get the patien data
  getPatient(): Promise<Patient> {
    var patient:Promise<Patient> = new Promise((resolve, reject) => {
      if(this.userData && this.userData.patient)
        resolve(this.userData.patient);
      else
        return this.patientService.getMe().then(patient => {
          this.setPatient(patient);
          resolve(patient);
        })
        .catch(e => {
          if(e.status == "409")
          {
            this.auth.logout();
            const dialogRef = this.dialog.open(ModalComponent, {
              data: {
                text:"Puede deberse a un cambio de credencial, falta de pago o baja del servicio. Consulte con su prestador y pruebe ingresar nuevamente.",
                textHeader: "Usuario inactivo"
              }
            });
            dialogRef.afterClosed().subscribe(resp => {
              this.router.navigate(['/validation'])
            });
          }
        });
    });    
    return patient;
  }

  getCallTimeoutMilliseconds(): any{
    return this.userData.patient.provider.callTimeoutMilliseconds;
  }

  getInfiniteCallTimeoutMilliseconds(): any{
    return this.userData.patient.provider.infiniteCallTimeoutMilliseconds;
  }

  // Set the affiliate data from db.
  setPatient(patient: Patient) {
    this.userData.patient = patient;
  }

  //Save boolean hasLoggedIn in localStorage
  setLoggedIn(hasLoggedIn){
    localStorage.setItem('hasLoggedIn', hasLoggedIn);
  }
  
  //Checks hasLoggedIn boolean in localStorage
  hasLoggedIn() {
    return localStorage.getItem('hasLoggedIn') == 'true' ? true : false;
  }

  //Save boolean hasPermissions in localStorage
  setPermissions(hasPermissions) {
    localStorage.setItem('hasPermissions', hasPermissions);
  }

  //Checks hasPermissions boolean in localStorage
  hasPermissions() {
    return localStorage.getItem('hasPermissions') == 'true' ? true : false;
  }

  //Save appointment in localStorage
  setAppointment(appointment) {
    localStorage.setItem('appointment', appointment);
  }

  //Get appointment from localStorage
  getAppointment() {
    return localStorage.getItem('appointment');
  }

  //Remove appointment from localStorage
  removeAppointment() {
    return localStorage.removeItem('appointment');
  }

  //Checks appointment in localStorage
  hasAppointment() {
    return localStorage.getItem('appointment') ? true : false;
  }

}
