import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Doctor } from '../models/doctor.models';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TokenExpiredService } from '../token-expired.service';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  url = '/doctor';

  constructor(protected requestService: RequestService,
    private auth: AuthService,
    private tokenExpired: TokenExpiredService) {  }

  getDoctor(id){
    return this.requestService.makeRequest({method:"GET"},this.url+'/'+id)
    .then((obj) => {
      return obj.json() as Doctor;
    })
  }

  loadDoctors(id){
    return this.requestService.makeRequest({method:"GET"},this.url)
    .then((obj) => {
      return obj.json() as Doctor[];
    })
  }

  searchDoctors(name,limit?,skip?): Observable<any> {
    var queryParams = {
      name: name
    }
    if(limit)
      queryParams['limit'] = limit
    if(skip)
      queryParams['skip'] = skip

    var options={
      method:"GET",
      urlQueryParams:queryParams,
      asObservable: true
    }
    return this.requestService.makeRequest(options,this.url).pipe(
      catchError(error => {
      if(error._body.name == "tokenValidationError"){
        this.tokenExpired.showModal();
        this.auth.logout()
      }
      return error
    })
    )
  }

  getBySpeciality(id:string, exclude?:Array<string>): Promise<any> {
    let urlQueryParams: any = {};

    urlQueryParams.specialty = id;

    if(exclude != undefined) {
      urlQueryParams.exclude = exclude.join();
    }

    var options={
      method:"GET",
      urlQueryParams:urlQueryParams
    }
    return this.requestService.makeRequest(options,this.url+"/random")
    .then((obj) => {
      return obj.json() as string[];
    })
  }
}
