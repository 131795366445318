export const environment = {
    production: false,
    SERVER_URL:"http://localhost",
    AUTH_0_DOMAIN:"lad-dev.auth0.com",
    AUTH_0_CLIENT_ID:"zMLFMDUNHqgRpCQLIAmJhnNr3YtveRNw",
    callbackAuth: window.location.origin,
    // AMPLITUDE_PROJECT_ID: '172348'
    OPENTOK_API_KEY:"45772292",
    AUTH_0_DATABASE_CONNECTION:"Affiliates-Username-Password-Authentication",
    DEFAULT_CALL_TIMEOUT:420, //7 minutos
    MIN_SECONDS_FOR_VALID_CALL:180, //3 minutos
    AUTOLOGIN_PROVIDER_NAME:"",
    AUTOLOGIN_USER_NAME:"",
    AUTOLOGIN_USER_PASSWORD:"",
    AUTOLOGIN_USER_VALIDATOR:"",
    AUTOLOGIN_USER_DOCUMENT_NUMBER:"",
    AUTOLOGIN_USER_EMAIL:"",
    REFERENCE_HELP_URL: "http://test.euda.com.ar/lad/instructions",
    USER_FAQS_URL: "https://test.euda.com.ar/lad/particulares/faqs_usuarios.html",
    API_KEY: "6f376593d1b466625de64efda5a4f5ca",
    Secret:  "101455a219651fe0422a7b44270d1847"
  };
  