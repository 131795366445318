import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {

  static readonly KEY = 'terms-and-conditions';

  constructor() { }

  hasUserAccepted() {
    return localStorage.getItem(TermsAndConditionsService.KEY)=='true'; // localStore store strings, convert to bool value
  }

  accept() {
    localStorage.setItem(TermsAndConditionsService.KEY, 'true');
  }
}
