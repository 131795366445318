import { Component, OnInit } from '@angular/core';
import { CallService } from '../../services/call.service';
import { Router } from '@angular/router';
import { CallByPatientDataService } from '../../services/call-by-patient-data.service';
import { FillerNavService } from 'src/app/services/filler-nav.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { AmplitudeService } from '../../services/amplitude.service';


@Component({
  selector: 'app-patient-rate-call',
  templateUrl: './patient-rate-call.component.html',
  styleUrls: ['./patient-rate-call.component.css']
})
export class PatientRateCallComponent implements OnInit {

  fillerNav;

  starsCall=[0,0,0,0,0];
  starsDoctor=[0,0,0,0,0];

  patientStars;
  doctorReputation;
  comments;

  constructor(
    protected router: Router,
    protected callService: CallService,
    protected callByPatientData:CallByPatientDataService,
    protected userData:UserDataService,
    protected fillerNavService:FillerNavService,
    public amplitudeService: AmplitudeService) {
      this.fillerNav = this.fillerNavService.getFiller();
  }

  ngOnInit() {
    this.amplitudeService.showEvent('paciente_calificando_llamada')
  }

  rateCall(index){
    this.starsCall=[0,0,0,0,0];
    for (let i = 0; i <= index; i++){
      this.starsCall[i]=1;
    }
    this.patientStars = index+1;
  }

  rateDoctor(index){
    this.starsDoctor=[0,0,0,0,0];
    for (let i = 0; i <= index; i++){
      this.starsDoctor[i]=1;
    }
    this.doctorReputation = index+1;
  }

  end(){
    var body = {
      patientStars: this.patientStars,
      doctorReputation: this.doctorReputation,
      patientText: this.comments
    }
    this.callService.rateCallPatient(this.callByPatientData.call.id, body).then(()=>{
      if(this.userData.hasAppointment())
        this.router.navigate(['/appointment-end']);
      else{
        this.amplitudeService.showEvent('paciente_califico_llamada')
        this.router.navigate(['/speciality-selection']);
      }
      this.callByPatientData.finished();
    }).catch((e)=>{
      if(this.userData.hasAppointment())
        this.router.navigate(['/appointment-end']);
      else
        this.router.navigate(['/speciality-selection']);
      this.callByPatientData.finished();
    })
  }

}
