import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import * as auth0 from 'auth0-js';
import { RegistrationDataService } from './registration-data.service';
import * as moment from 'moment';
import { LocalizationService } from './localization.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import * as _ from 'lodash';

(window as any).global = window;

@Injectable()
export class AuthService {

  jwt:any;

  private auth0 = new auth0.WebAuth({
    domain: environment.AUTH_0_DOMAIN,
    clientID: environment.AUTH_0_CLIENT_ID,
    responseType: 'token id_token',
    redirectUri: environment.callbackAuth,
    scope:'openid',
  });

  constructor(public router: Router,
    protected registrationDataService:RegistrationDataService,
    protected localizationService:LocalizationService,
    protected cookieService:CookieService) {
      this.jwt = new JwtHelperService()
    }

  public login(user?,pass?): void {

    var optionalData = {
      databaseConnection:environment.AUTH_0_DATABASE_CONNECTION,
      providerLogo: this.registrationDataService.getAffiliate().provider.image,
      lenguage: this.localizationService.getLocale()
    }
    if(user)
      optionalData['user'] = user;
    if(pass)
      optionalData['pass'] = pass;
    this.auth0.authorize(optionalData);
  }

  public signup(userData:any): void {
    userData.connection = environment.AUTH_0_DATABASE_CONNECTION;
    var affiliate = this.registrationDataService.getAffiliate()
    this.auth0.signup(userData, (err,authResult)=>{
      if(authResult)
      {
        this.login(affiliate.email);
      }
    });
  }

  // ...
  public handleAuthentication(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject)=>{

      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          window.location.hash = '';
          this.setSession(authResult);
  
          resolve(true);
          return;
        }
  
        reject(false);
  
      });
    })

  }

  private setSession(authResult): void {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  public setAutologinSession(idToken, expiresAt, notBefore?): void {
    // Set the time that the Access Token will expire at
    localStorage.setItem('expires_at', JSON.stringify((expiresAt)));
    localStorage.setItem('id_token', idToken);
    if(notBefore)
      localStorage.setItem('not_before', JSON.stringify((notBefore)));
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('not_before');
    this.cookieService.deleteAll();
  }

  public isAuthenticated(): boolean {
    var token = localStorage.getItem('id_token');
    if(!token)
      return false;

    return !this.jwt.isTokenExpired(token);
  }

  public getIdToken() {
    return localStorage.getItem('id_token');
  }

}