import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppointmentService } from 'src/app/services/appointment.service';

@Component({
  selector: 'app-appointment-end',
  templateUrl: './appointment-end.component.html',
  styleUrls: ['./appointment-end.component.css']
})
export class AppointmentEndComponent implements OnInit {

  message;

  constructor(private appointmentService: AppointmentService,
    protected userData:UserDataService,
    protected authService: AuthService) { }

  ngOnInit() {
    switch (this.appointmentService.getEndStatus()) {
      case "ended":
        this.message = "Completaste el turno con éxito. Gracias por usar nuestro servicio."
        break;

      case "notAvailableDoctor":
        this.message = "El doctor no estaba disponible durante el horario del turno."
        break;

      case "timeout":
        this.message = "Finalizó el tiempo del turno."
        break;

      default:
        this.message = "Completaste el turno con éxito. Gracias por usar nuestro servicio."
        break;
    }
  }

}
