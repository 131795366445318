import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../node_modules/@angular/material';
import * as _ from 'lodash';

@Component({
  selector: 'app-no-affiliate',
  templateUrl: './no-affiliate.component.html',
  styleUrls: ['./no-affiliate.component.css']
})
export class NoAffiliateComponent implements OnInit {

  message:string;

  constructor(public dialogRef: MatDialogRef<NoAffiliateComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.message=_.clone(this.data.message);
  }

  close(): void {
    this.dialogRef.close();
  }

  info():void {
    window.location.href = 'http://llamandoaldoctor.com/particulares/hace_tu_consulta.html'
  }

}
