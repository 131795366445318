import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  transform(src:string): string {
    if (src)
      return src.replace(/^http:/, 'https:');
  }

}
