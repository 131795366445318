import { Injectable } from '@angular/core';
import { LocalizationService } from './localization.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FillerNavService {

  fillerNav = [
    {title: "", route: "/speciality-selection", icon:"view_list"},
    {title: "", route: "/profile", icon:"settings"},
    {title: "", route: "/search", icon:"search"},
    {title: "", route: "/logout", icon:"power_settings_new"}
  ]

  constructor(protected localizationService:LocalizationService,
    private auth: AuthService) {
    switch (this.localizationService.getLocale()) {
      case "pt":
      case "pt-br":
        this.fillerNav[0].title = "Especialidades"
        this.fillerNav[1].title = "Meu Perfil"
        this.fillerNav[2].title = "Pesquisa"
        this.fillerNav[3].title = "Sair"
        break;
      case "en":
      case "en-us":
        this.fillerNav[0].title = "Specialties"
        this.fillerNav[1].title = "My Profile"
        this.fillerNav[2].title = "Search"
        this.fillerNav[3].title = "Logout"
        break;
      default:
        this.fillerNav[0].title = "Especialidades"
        this.fillerNav[1].title = "Mi Perfil"
        this.fillerNav[2].title = "Búsqueda"
        this.fillerNav[3].title = "Cerrar sesión"
        break;
    }

  }

  public getFiller():any {
    return this.fillerNav;
  }
}
