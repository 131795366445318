import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { timer, Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-appointment-time',
  templateUrl: './appointment-time.component.html',
  styleUrls: ['./appointment-time.component.css']
})
export class AppointmentTimeComponent implements OnInit {

  timer:Subscription;
  timeLapse = 1000;//miliseconds
  countdownText;

  constructor(public dialogRef: MatDialogRef<AppointmentTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    let dateToStartAppointment = moment().add(this.data.countdownTime ? this.data.countdownTime : "600",'seconds') // "600" -> 10 minutes in seconds
    this.timer = timer(0, this.timeLapse)
    .subscribe(() => {
      let diff = dateToStartAppointment.diff(moment(),'seconds')
      const minutes:number = Math.floor(diff / 60);
      this.countdownText = ('00' + minutes).slice(-2) + ':' + ('00' + diff % 60).slice(-2);
      if(diff<=0) this.close();
    })
  }

  close(){
    this.timer.unsubscribe();
    this.dialogRef.close();
  }

}
