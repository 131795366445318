export class MedicalHistory {
    readonly id:string;
    readonly name:string;
    selected:boolean;

    public constructor(id:string, name:string) {
        this.id = id;
        this.name = name;
        this.selected = false;
    }

    public equals(obj:MedicalHistory):boolean {
        return obj.id==this.id;
    }
}