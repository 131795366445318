import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  url = '/appointment';
  retryTimes = 2;
  endStatus;

  constructor(protected requestService: RequestService) { }

  get(appointmentId:any): Promise<any> {
    return this.requestService.makeRequest({method:"GET"},this.url+"/"+appointmentId)
    .then((obj) => {
      return obj.json();
    })
    .catch((err)=>{
      return Promise.reject(err._body)
    })
  }

  available(appointmentId:any): Promise<any> {
    return this.requestService.makeRequest({method:"GET", retryTimes: this.retryTimes},this.url+"/"+appointmentId+"/available")
    .then((obj) => {
      return obj.json();
    })
    .catch((err)=>{
      return Promise.reject(err._body)
    })
  }

  isExpired(appointmentEndTime){
    let endTime = moment(appointmentEndTime).subtract(1,'minutes')
    let now = moment()
    if(now.isAfter(endTime))
      return true;
    
    return false;
  }

  setEndStatus(status){
    this.endStatus = status;
  }

  getEndStatus(){
    return this.endStatus
  }
}
