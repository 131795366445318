import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../services/user-data.service';
import { Router } from '@angular/router';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-ask-permissions',
  templateUrl: './ask-permissions.component.html',
  styleUrls: ['./ask-permissions.component.css']
})
export class AskPermissionsComponent implements OnInit {

  hasPermissions:boolean = false;
  anotherCamaraOpen:boolean = false;
  providerName;
  popupImage;

  constructor(protected router: Router,
    protected userDataService: UserDataService,
    protected localizationService:LocalizationService ) { }

  ngOnInit() {
    this.popupImage = this.getPopup();
    this.userDataService.getPatient().then((patient)=>{
      if(patient)
      {
        this.providerName = patient.provider.name;
      }
    })
    
    if(!this.userDataService.hasPermissions())
    {
      var constraints = { audio: true, video: true };
      var self = this;

      navigator.mediaDevices.getUserMedia(constraints)
      .then(function(mediaStream) {
        var tracks = mediaStream.getTracks();
        tracks.forEach(track => {
          track.stop();
        });

        self.userDataService.setPermissions("true");
        self.hasPermissions = true;
      })
      .catch(function(err) { 
        console.log(err.name + ": " + err.message); 
        self.anotherCamaraOpen = true;
      }); 
    }
    else if(this.userDataService.hasPermissions() == true)
    {
      this.hasPermissions = true;
    }
  }

  continue() {
    this.router.navigate(['/warning']);
  }

  getPopup(): any {
    switch (this.localizationService.getLocale()) {
      case "pt":
      case "pt-br":
        return "assets/popup_permisos-pt.png";
      case "en":
      case "en-us":
        return "assets/popup_permisos-en.png";
      default:
        return "assets/popup_permisos.png";
    }
  }
}
