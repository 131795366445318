// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SERVER_URL:"//dev.api.llamandoaldoctor.com",
  AUTH_0_DOMAIN:"lad-dev.auth0.com",
  AUTH_0_CLIENT_ID:"zMLFMDUNHqgRpCQLIAmJhnNr3YtveRNw",
  //** Los dejo comentados porque en el BO se usan pero aca todavia no estoy seguro si los vamos a usar */
  callbackAuth: window.location.origin,
  // AMPLITUDE_PROJECT_ID: '172348'
  OPENTOK_API_KEY:"45772292",
  AUTH_0_DATABASE_CONNECTION:"Affiliates-Username-Password-Authentication",
  DEFAULT_CALL_TIMEOUT:420, //7 minutos
  MIN_SECONDS_FOR_VALID_CALL:180, //3 minutos
  AUTOLOGIN_PROVIDER_NAME:"BBVA Colombia-1",
  AUTOLOGIN_USER_NAME:"BBVADEV",
  AUTOLOGIN_USER_PASSWORD:"123456",
  AUTOLOGIN_USER_VALIDATOR:"11",
  AUTOLOGIN_USER_DOCUMENT_NUMBER:"33333333",
  AUTOLOGIN_USER_EMAIL:"bbvadev@eudaimonia.com.ar",
  REFERENCE_HELP_URL: "http://test.euda.com.ar/lad/instructions",
  USER_FAQS_URL: "https://test.euda.com.ar/lad/particulares/faqs_usuarios.html",
  API_KEY: "6f376593d1b466625de64efda5a4f5ca",
  Secret:  "101455a219651fe0422a7b44270d1847"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
