import { Component, OnInit } from '@angular/core';
import { MedicalHistoryService } from '../../services/medical-history.service';
import { MedicalHistory } from '../../models/medical-history';
import { Router } from '@angular/router';
import { CallByPatientDataService } from '../../services/call-by-patient-data.service';
import { FillerNavService } from 'src/app/services/filler-nav.service';
import { AmplitudeService } from '../../services/amplitude.service';

@Component({
  selector: 'app-medical-records',
  templateUrl: './medical-records.component.html',
  styleUrls: ['./medical-records.component.scss']
})
export class MedicalRecordsComponent implements OnInit {

  fillerNav;

  constructor(protected router: Router,
    public medicalHistoryService:MedicalHistoryService,
    public amplitudeService: AmplitudeService,
    protected fillerNavService:FillerNavService) {
      this.fillerNav = this.fillerNavService.getFiller();
  }

  ngOnInit() {
  }

  continue() {
    this.amplitudeService.showEvent('paciente_selecciono_antecedentes');
    this.router.navigate(['/calling']);
  }

  back() {
    this.router.navigate(['/patient-reason']);
  }

}
