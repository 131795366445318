import { MedicalHistory } from "./medical-history";

export class MedicalHistoryCategory {
    name:string;
    records:Array<MedicalHistory>;

    public constructor(name:string, records:Array<MedicalHistory>){
        this.name = name;
        this.records = records;
    }
}