import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationDataService } from '../../services/registration-data.service';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-registration-first-step',
  templateUrl: './registration-first-step.component.html',
  styleUrls: ['./registration-first-step.component.scss']
})
export class RegistrationFirstStepComponent implements OnInit {
  
  user = {
    email:'',
    confirmEmail:'',
    password:'',
    confirmPassword:''
  }
  affiliate:any;
  logo:string;

  constructor(protected router: Router,
    protected registrationDataService: RegistrationDataService,
    protected localizationService:LocalizationService) { }

  ngOnInit() {
    this.logo = this.getLogo();
    this.affiliate = this.registrationDataService.getAffiliate();
    if(this.affiliate)
    {
      this.user.email = this.affiliate.email;
    }
    else
    {
      this.router.navigate(['']);
    }
  }

  continue(email,password) {
    if(email.valid && password.valid)
    {
      this.affiliate.email = this.user.email;
      this.affiliate.password = this.user.password;
      this.registrationDataService.setAffiliate(this.affiliate);
      this.router.navigate(['/registration-second']);
    }
  }

  getLogo(): any {
    switch (this.localizationService.getLocale()) {
      case "pt":
      case "pt-br":
        return "assets/logo-pt.png";
      case "en":
      case "en-us":
        return "assets/logo-en.png"
      default:
        return "assets/logo.png";
    }
  }

}
