import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpecialitiesService } from '../../services/specialities.service';
import { CallByPatientDataService } from '../../services/call-by-patient-data.service';
import { FillerNavService } from 'src/app/services/filler-nav.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { AmplitudeService } from '../../services/amplitude.service';

@Component({
  selector: 'app-speciality-selection',
  templateUrl: './speciality-selection.component.html',
  styleUrls: ['./speciality-selection.component.scss']
})
export class SpecialitySelectionComponent implements OnInit {

  fillerNav;
  specialities;

  constructor(protected router: Router,
    protected callByPatientData:CallByPatientDataService,
    protected specialitiesService:SpecialitiesService,
    protected localizationService:LocalizationService,
    protected fillerNavService:FillerNavService,
    public amplitudeService: AmplitudeService) {
      this.fillerNav = this.fillerNavService.getFiller();
  }

  ngOnInit() {
    this.specialities = this.specialitiesService.getSpecialties()
    this.callByPatientData.doctorToCall = undefined
    this.specialities.map(specialty => {
      if(specialty.localizedName)
        return specialty.localized = this.localizationService.getSpecialtyNameLocalized(specialty);
    });
  }

  selectSpeciality(specialityId){
    if (specialityId === "3") {
      //Assign specialityId "0" (general medicine) to covid call
      specialityId = "0"
      this.callByPatientData.speciality = specialityId
      this.router.navigate(['/coronavirus-info'])
    } else {
      this.callByPatientData.speciality = specialityId;
      this.amplitudeService.showEvent('paciente_selecciono_especialidad', { especialidad: this.specialitiesService.getById(specialityId).name})
      this.router.navigate(['/patient-reason']);
    }
  }
}
