import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { TermsAndConditionsService } from '../../services/terms-and-conditions.service';
import { UserDataService } from '../../services/user-data.service';
import { RegistrationDataService } from '../../services/registration-data.service';
import { PatientService } from '../../services/patient.service';
import { AffiliatesService } from '../../services/affiliates.service';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material';
import { NotAllowedNavigatorComponent } from 'src/app/dialogs/not-allowed-navigator/not-allowed-navigator.component';
import { resolveDirective } from '@angular/core/src/render3/instructions';
import { LocalizationService } from 'src/app/services/localization.service';
import { ModalComponent } from '../modal/modal.component';
import { AppointmentService } from 'src/app/services/appointment.service';
import { MessageComponent } from 'src/app/dialogs/message/message.component';
import { AmplitudeService } from '../../services/amplitude.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  helper:any;
  id: any;

  constructor(public auth: AuthService,
    public router: Router,
    public termsService: TermsAndConditionsService,
    public userDataService: UserDataService,
    public registrationDataService: RegistrationDataService,
    public patientService: PatientService,
    public activatedRoute: ActivatedRoute,
    public affiliatesService: AffiliatesService,
    public authService:AuthService,
    public appointmentService:AppointmentService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    public localizationService: LocalizationService,
    public amplitudeService: AmplitudeService) {
      this.helper = new JwtHelperService();
  }

  ngOnInit() {
    if(this.deviceService.isMobile() && this.deviceService.os == "mac" && this.deviceService.browser != "safari")
    {
      this.openNotAllowedNavigatorDialog('ios');
    }
    else if(this.deviceService.isMobile() && this.deviceService.os == "android" && this.deviceService.browser != "chrome" && this.deviceService.browser!= "firefox")
    {
      this.openNotAllowedNavigatorDialog('android');
    }
    else
    {
      this.authenticate();
    }
  }

  openNotAllowedNavigatorDialog(os): void {
    const dialogRef = this.dialog.open(NotAllowedNavigatorComponent, {
      data: {os: os}
    });
    dialogRef.afterClosed().subscribe(editedUser => {});
  }

  authenticate() {
    this.activatedRoute.queryParams
    .subscribe(params => {
      if(!_.isEmpty(params))
      {
        if(params.token){
          if(this.helper.isTokenExpired(params.token))
          {
            if (params.appointment)
              this.dialog.open(ModalComponent, {data: {text:"El turno al que estás intentando ingresar ya expiró.",textHeader: "¡Atención!"}});
            else
              this.userDataService.removeAppointment();

            this.router.navigate(['/validation']);
          }
          else
          {
            if (params.appointment)
              this.userDataService.setAppointment(params.appointment);
            else
              this.userDataService.removeAppointment();

            this.loginByToken(params.token);
          }
        }
        if(params.provider_name && params.provider_name==environment.AUTOLOGIN_PROVIDER_NAME)
        {
          this.amplitudeService.showEvent('login_provider_name');
          this.loginByProviderName();
        }
      }
      else
      {
        if(this.auth.isAuthenticated()){
          this.redirect();
        }
        else
        {
          this.loginByAuth0();
        }
      }
    });
  }

  hasValidAppointment(appointment){
    this.appointmentService.available(appointment)
      .then((appointmentStatus)=>{
        if(appointmentStatus.status) {
          this.router.navigate(['/registration-second']);
        } else {
          this.dialog.open(MessageComponent, { data: {title: "¡Error!", message: "No existe este turno, por favor revisa que ingresaste el link correctamente." }, disableClose: true });
        }
      })
      .catch((err)=>{
        let message;
        if(err.appointmentStatus == "ended")
          message = "El turno ya finalizó."
        this.dialog.open(MessageComponent, { data: {title: "¡Error!", message: err.msg ? err.msg : message }, disableClose: true });
      })
  }

  loginByProviderName() {
    this.authService.logout();

    var body= {
      email: environment.AUTOLOGIN_USER_EMAIL,
      documentNumber: environment.AUTOLOGIN_USER_DOCUMENT_NUMBER,
      validator: environment.AUTOLOGIN_USER_VALIDATOR
    }
    return this.affiliatesService.getOne(environment.AUTOLOGIN_USER_DOCUMENT_NUMBER,body).then((affiliate)=>{
      this.registrationDataService.setAffiliate(affiliate);
      this.userDataService.setLoggedIn(affiliate.hasLoggedIn);
      this.userDataService.setPatient(affiliate);
      this.authService.login(environment.AUTOLOGIN_USER_EMAIL,environment.AUTOLOGIN_USER_PASSWORD);
    });
  }

  loginByToken(token) {
    this.authService.logout();
    localStorage.removeItem('currentRoute');

    let decodedToken = this.helper.decodeToken(token);
    var notBefore = undefined
    if(decodedToken.nbf) notBefore = decodedToken.nbf
    var expiresAt = this.helper.getTokenExpirationDate(token);
    expiresAt = moment(expiresAt).unix();

    this.authService.setAutologinSession(token,expiresAt,notBefore);
    this.patientService.getMe().then((patient)=>{
      sessionStorage.removeItem('locale');
      this.localizationService.use(patient.provider.language);
      this.userDataService.setLoggedIn(patient.hasLoggedIn);
      if(this.userDataService.hasAppointment())
      {
        this.hasValidAppointment(this.userDataService.getAppointment())
      } else {
        this.amplitudeService.setUser(patient.id);
        this.amplitudeService.showEvent('login_token', { token });
        // Send token patient to registration second every time they log in. If we have to change that we should send them to this.redirect()
        this.router.navigate(['/registration-second']);
      }
    });
  }

  loginByAuth0() {
    this.userDataService.removeAppointment();
    this.auth.handleAuthentication().then((isLoggedAndCreated)=>{
      this.amplitudeService.showEvent('login_Auth0');
      if(isLoggedAndCreated && !this.userDataService.hasLoggedIn()) //user logged.
      {
        var affiliateData = this.registrationDataService.getAffiliateDataFromLocalStorage();
        this.patientService.create(affiliateData).then((affiliate)=>{
          this.registrationDataService.removeAffiliateDataFromLocalStorage();

          this.redirect();
        });
      }
      else if(this.userDataService.hasLoggedIn())
      {
        this.redirect();
      }
      else
      {
        this.router.navigate(['/validation']);
      }
    }).catch(e => {
      this.router.navigate(['/validation']);
    })
  }

  redirect() {
    this.patientService.getMe().then((patient)=>{

      //EVENT FOR ANALYTICS
      (<any>window).ga('send', 'event', {
        eventCategory: 'user-login',
        eventLabel: "Provider Id: "+patient.provider.id+" - Provider Name: "+patient.provider.name,
        eventAction: 'login',
        eventValue: 10
      });
      this.amplitudeService.setUser(patient.id);
      this.localizationService.use(patient.provider.language);
      this.userDataService.setPatient(patient);

      if (_.isEmpty(patient.email) || _.isEmpty(patient.birthDate) || (_.isEmpty(patient.phone) && _.isEmpty(patient.mobilePhone)) )
      {
        this.router.navigate(['/registration-second']);
      }

      if(this.termsService.hasUserAccepted())
      {
        if(this.userDataService.hasPermissions())
          this.router.navigate(['/warning']);
        else
          this.router.navigate(['/ask-permissions']);
        }
      else
      {
        this.router.navigate(['/terms-and-conditions']);
      }
    }).catch(e => {
      if(e.status == "401"){
        this.authService.logout();
        this.router.navigate(['/validation'])
      }
      if(e.status == "409"){
        this.authService.logout();
        const dialogRef = this.dialog.open(ModalComponent, {
          data: {
            text:"Puede deberse a un cambio de credencial, falta de pago o baja del servicio. Consulte con su prestador y pruebe ingresar nuevamente.",
            textHeader: "Usuario inactivo"
          }
        });
        dialogRef.afterClosed().subscribe(resp => {
          this.router.navigate(['/validation'])
        });
      }
    })
  }
}
