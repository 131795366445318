import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { TokenExpiredService } from '../token-expired.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedService implements CanActivate {

  constructor(private auth: AuthService, private tokenExpired:TokenExpiredService) {}

  canActivate() {

    if(this.auth.isAuthenticated()) {
      return true;
    } else {
      this.tokenExpired.showModal();
      this.auth.logout()
      return false;
    }
  }
}