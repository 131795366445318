import { Component, OnInit, Input } from '@angular/core';
import { MedicalHistoryCategory } from '../../models/medical-history-category';
import { MedicalHistoryService } from '../../services/medical-history.service';
import { MedicalHistory } from '../../models/medical-history';

@Component({
  selector: 'app-medical-history-category',
  templateUrl: './medical-history-category.component.html',
  styleUrls: ['./medical-history-category.component.scss']
})
export class MedicalHistoryCategoryComponent implements OnInit {

  @Input() category:MedicalHistoryCategory;

  constructor(protected medicalHistoryService:MedicalHistoryService) { }

  ngOnInit() {
  }

  onChange(record:MedicalHistory){
    if(record.equals(this.medicalHistoryService.noBackgroundHistory)) {
        this.medicalHistoryService.setNoBackgroundHistory();
    } else {
      this.medicalHistoryService.noBackgroundHistory.selected = false;
    }
    this.medicalHistoryService.validate();
  }


}
