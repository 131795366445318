import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Call } from '../models/call.models';
import { Doctor } from '../models/doctor.models';
import { Patient } from '../models/patient.models';

@Injectable({
  providedIn: 'root'
})
export class CallService {

  url = '/call';

  constructor(protected requestService: RequestService) {  }

  createCall(doctor:any, patient:Patient, records:string[], motive:string, appointment?): Promise<Call> {

    let params = {
      doctor: typeof doctor == 'string'? doctor:doctor.id,
      patient: patient.id,
      records: records,
      motive: motive
    }

    if(appointment)
      params['appointment'] = appointment

    var options={
      method:"POST",
      body: params
    }
    return this.requestService.makeRequest(options,this.url)
    .then((obj) => {
      return obj.json() as Call;
    })
  }

  createCallBySpecialty(specialtyId:string, patient:Patient, records:string[], motive:string): Promise<Call> {

    let params = {
      patient: patient.id,
      records: records,
      motive: motive
    }

    var options={
      method:"POST",
      body: params
    }

    return this.requestService.makeRequest(options,this.url+"/specialty/"+specialtyId+"/random")
    .then((obj) => {
      return obj.json() as Call;
    })
  }

  cancel(id:string){
    let options={
      method:"PUT"
    }
    return this.requestService.makeRequest(options,this.url+'/'+id+'/cancel')
    .then((obj) => {
      return obj.json();
    })
  }

  timeoutCall(id:string) {
    let options={
      method:"PUT"
    }
    return this.requestService.makeRequest(options,this.url+'/'+id+'/timeout')
    .then((obj) => {
      return obj.json() as Call;
    })
  }

  endCallPatient(id, patientDuration, connectionLost){
    
    let options={
      method:"PUT",
      body: { patientDuration: patientDuration, connectionLost: connectionLost }
    }
    return this.requestService.makeRequest(options,this.url+'/'+id+'/end')
    .then((obj) => {
      return obj.json()
    })
  }

  rateCallPatient(id,body){
    let options={
      method:"PUT",
      body:JSON.stringify(body)
    }
    return this.requestService.makeRequest(options,this.url+'/'+id+'/rate')
    .then((obj) => {
      return obj.json()
    })
  }

  validateCall(id){
    let options={
      method:"PUT"
    }
    return this.requestService.makeRequest(options,this.url+'/'+id+'/validate')
    .then((obj) => {
      return obj.json()
    })
  }
}
