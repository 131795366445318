import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'epochToYearsOld'
})
export class EpochToYearsOldPipe implements PipeTransform {

  transform(timeInEpoch: any): any {
    var date = moment();
    var birthday = moment.unix(timeInEpoch).utc();
    return date.diff(birthday,'years');
  }

}
