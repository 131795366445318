import { Injectable } from '@angular/core';
import { Http, Headers, ResponseContentType } from '@angular/http';
import { environment } from '../../environments/environment';

import * as _ from 'lodash';
import { AuthService } from './auth.service';
import { LocalizationService } from './localization.service';
import { TokenExpiredService } from '../token-expired.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private baseUrl: string;
  private headers: Headers;
  private retryTimes = 0;

  constructor(private http: Http, 
    private auth: AuthService,
    private tokenExpired: TokenExpiredService,
    protected localizationService: LocalizationService) {
    this.baseUrl = environment.SERVER_URL;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  };

  completeUrlWithQuery(url,options) {
    let urlQueryParams: any = options.urlQueryParams || {};
    _.each(urlQueryParams, function (value: any, key: string) {

      url += (_.indexOf(url, '?') > 0 ? '&' : '?') + key + '=' + value;
    });

    return url;
  }

  /**
   * 
   * @param requestData The options for the request (example in the function)
   * @param urlEndpoint Example: "/radio/1564654xzda5646"
   */
  makeRequest(requestData: any,urlEndpoint:string): any {
    this.headers = new Headers({
    'Content-Type': 'application/json',
    'Accept-Language': this.localizationService.getHeaderLocale()
    });

    if(this.auth.getIdToken())
    {
      this.headers.append('Authorization', 'Bearer ' + this.auth.getIdToken())
    }

    let options: any = {
      method: requestData.method || 'get',
      body: requestData.body,
      headers: this.headers,
      responseType: ResponseContentType.Json
    };

    if (!_.isUndefined(requestData.responseType))
      options.responseType = requestData.responseType

    if (!_.isUndefined(requestData.retryTimes))
      this.retryTimes = requestData.retryTimes
    
    let url = this.completeUrlWithQuery(this.baseUrl+urlEndpoint,requestData)
    let reqObs = this.http.request(url, options)

    if (requestData.asObservable) {
      return reqObs;
    }

    return reqObs.toPromise().catch((error)=>{
      if(this.retryTimes > 0)
      {
        requestData.retryTimes--
        return this.makeRequest(requestData,urlEndpoint)
      }
      else
      {
        if(error._body.name == "tokenValidationError"){
          this.tokenExpired.showModal();
          this.auth.logout()
        }
        return this.handleError(error)
      }
    });
  }
}
