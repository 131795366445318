import { Injectable } from '@angular/core';
import { Router,CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from './user-data.service';
import { TermsAndConditionsService } from './terms-and-conditions.service';
import { AuthService } from './auth.service';
import { PatientService } from './patient.service';
import * as _ from 'lodash';
import { ModalComponent } from '../components/modal/modal.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class RouteFlowCheckerService implements CanActivate{

  constructor(private router: Router,
    protected userDataService: UserDataService,
    protected termsService:TermsAndConditionsService,
    public patientService: PatientService,
    private dialog: MatDialog,
    public auth: AuthService) { }

  actualRoute;

  verifiedFlowRoutes = {
    // Ej: Define from which route is allowed to redirect to which one
    // 'from-where': ['to-where-is-allowed']
    '':['validation','warning','ask-permissions','terms-and-conditions'],
    'validation':['registration-first','warning','terms-and-conditions'],
    'terms-and-conditions':['warning','ask-permissions','validation','registration-second'],
    'speciality-selection':['patient-reason','coronavirus-info','search','profile'],
    'registration-first':['registration-second'],
    'warning':['speciality-selection','patient-reason'],
    'patient-reason':['medical-records','speciality-selection','search','profile'],
    'medical-records':['calling','patient-reason'],
    'profile':['validation','speciality-selection','search'],
    'patient-rate-call':['speciality-selection','search','profile','appointment-end'],
    'ask-permissions':['warning'],
    'calling':['speciality-selection','search','profile','patient-rate-call','appointment-end'],
    'search':['patient-reason','calling','speciality-selection','search','profile'],
    'coronavirus-info':['patient-reason','speciality-selection','search','profile']
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    var navigateTo = state.url.replace('/','');

    if(!this.getCurrentRoute()) // if there is no current route, navigate to origin -> '/' and set 'validation' as current
    {
      return this.returnTrue(navigateTo);
    }

    if(this.getCurrentRoute() && this.getCurrentRoute()===navigateTo) // if is the same page, like in refreshing, navigate to origin '/'
    {
      return this.returnFalse();
    }

    if(this.verifiedFlowRoutes[this.getCurrentRoute()] && this.verifiedFlowRoutes[this.getCurrentRoute()].includes(navigateTo)) // check if the route has the current as allowed to came from
    {
      return this.returnTrue(navigateTo);
    }
    else
    {
      return this.returnFalse(); // if all fails, navigate to origin -> '/'
    }
  }

  returnTrue(navigateTo){
    this.setCurrentRoute(navigateTo);
    return true;
  }

  returnFalse(){
    this.deleteCurrentRoute();
    this.redirect();
    return false;
  }

  redirect(){
    if(this.auth.isAuthenticated()){
      this.patientService.getMe().then((patient)=>{
        if (patient && (_.isEmpty(patient.email) || _.isNil(patient.birthDate) || (_.isEmpty(patient.phone) && _.isEmpty(patient.mobilePhone)) ) ){
          this.router.navigate(['/registration-second']);
        } else {
          if(this.termsService.hasUserAccepted()) {
              if(this.userDataService.hasPermissions())
                this.router.navigate(['/warning']);
              else
                this.router.navigate(['/ask-permissions']);
          } else {
              this.router.navigate(['/terms-and-conditions']);
            }
          }
      }).catch(e => {
        if(e.status == "409")
        {
          this.auth.logout();
          const dialogRef = this.dialog.open(ModalComponent, {
            data: {
              text:"Puede deberse a un cambio de credencial, falta de pago o baja del servicio. Consulte con su prestador y pruebe ingresar nuevamente.",
              textHeader: "Usuario inactivo"
            }
          });
          dialogRef.afterClosed().subscribe(resp => {
            this.router.navigate(['/validation'])
          });
        }
      });
    } else {
      this.router.navigate(['/validation']);
    }
  }

  setCurrentRoute(route:string){
    localStorage.setItem('currentRoute', route);
  }

  getCurrentRoute():string{
    return localStorage.getItem('currentRoute');
  }

  deleteCurrentRoute(){
    localStorage.removeItem('currentRoute');
  }
}
