import { Injectable } from '@angular/core';
import { Speciality } from '../models/speciality.models';
import { RequestService } from './request.service';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class SpecialitiesService {

  private specialties:Array<Speciality>  = [
    { id:"0", name:"Medicina General", degree:"Médico general", image:"/assets/icon_medicina.png", realId:"", prioritizedDoctors:[] },
    { id:"1", name:"Pediatría", degree:"Pediatra", image:"", realId:"", prioritizedDoctors:[] },
    { id:"2", name:"Ginecología/Obstetricia", degree:"Ginecólogo/Obstetra", image:"", realId:"", prioritizedDoctors:[] }
  ];

  constructor(protected userDataService: UserDataService,protected requestService: RequestService) {
    this.userDataService.getPatient().then((patient)=>{
      if(patient && patient.provider.specialties != undefined && patient.provider.specialties.length != 0)
        this.specialties = patient.provider.specialties;
    });
  }

  public getSpecialties():Array<Speciality> {
    return this.specialties;
  }

  public getById(id:string):Speciality {
    for(var speciality of this.specialties) {
      if(speciality.id == id || speciality.realId == id)
        return speciality;
    }

    return null;
  }

  public getOne(id:string):Promise<Speciality> {
    return this.requestService.makeRequest({},'/specialty/'+id)
    .then((obj) => {
      return obj.json() as Speciality;
    })
  }
}