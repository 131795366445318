import { Injectable } from '@angular/core';
import { MedicalHistory } from '../models/medical-history';
import { MedicalHistoryCategory } from '../models/medical-history-category';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class MedicalHistoryService {
  categories: Array<MedicalHistoryCategory>;
  medicalHistoriesById: Map<String, MedicalHistory>;

  histories:Array<MedicalHistory>;

  noBackgroundHistory: MedicalHistory;

  isValid:boolean;

  medicalHistoryOptions;
  medicalHistoryCategoryNames;

  public constructor(protected localizationService:LocalizationService) {
    this.fillMedicalHistoryOptions()
    this.categories = new Array<MedicalHistoryCategory>();

    this.histories = new Array<MedicalHistory>();

    this.medicalHistoriesById = new Map<String, MedicalHistory>();

    var noBackground: Array<MedicalHistory> = new Array<MedicalHistory>();
    this.noBackgroundHistory = new MedicalHistory("0", this.medicalHistoryOptions[0].name);
    noBackground.push(this.noBackgroundHistory);
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[0].name, noBackground));

    var hipertension: Array<MedicalHistory> = new Array<MedicalHistory>();
    hipertension.push(new MedicalHistory("1", this.medicalHistoryOptions[1].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[1].name,hipertension));

    var diabetes: Array<MedicalHistory> = new Array<MedicalHistory>();
    diabetes.push(new MedicalHistory("2", this.medicalHistoryOptions[2].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[2].name,diabetes));

    var bajoPeso: Array<MedicalHistory> = new Array<MedicalHistory>();
    bajoPeso.push(new MedicalHistory("35", this.medicalHistoryOptions[35].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[3].name,bajoPeso));

    var sobrePeso: Array<MedicalHistory> = new Array<MedicalHistory>();
    sobrePeso.push(new MedicalHistory("36", this.medicalHistoryOptions[36].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[4].name,sobrePeso));

    var hipo: Array<MedicalHistory> = new Array<MedicalHistory>();
    hipo.push(new MedicalHistory("3", this.medicalHistoryOptions[3].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[5].name,hipo));

    var habits: Array<MedicalHistory> = new Array<MedicalHistory>();
    habits.push(new MedicalHistory("4", this.medicalHistoryOptions[4].name));
    habits.push(new MedicalHistory("5", this.medicalHistoryOptions[5].name));
    habits.push(new MedicalHistory("6", this.medicalHistoryOptions[6].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[6].name,habits));

    var alergies: Array<MedicalHistory> = new Array<MedicalHistory>();
    alergies.push(new MedicalHistory("7", this.medicalHistoryOptions[7].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[7].name,alergies));

    var pregnancy: Array<MedicalHistory> = new Array<MedicalHistory>();
    pregnancy.push(new MedicalHistory("37", this.medicalHistoryOptions[37].name));
    pregnancy.push(new MedicalHistory("38", this.medicalHistoryOptions[38].name));
    pregnancy.push(new MedicalHistory("39", this.medicalHistoryOptions[39].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[8].name,pregnancy));

    var neurologics: Array<MedicalHistory> = new Array<MedicalHistory>();
    neurologics.push(new MedicalHistory("8", this.medicalHistoryOptions[8].name));
    neurologics.push(new MedicalHistory("40", this.medicalHistoryOptions[40].name));
    neurologics.push(new MedicalHistory("9", this.medicalHistoryOptions[9].name));
    neurologics.push(new MedicalHistory("10", this.medicalHistoryOptions[10].name));
    neurologics.push(new MedicalHistory("41", this.medicalHistoryOptions[41].name));
    neurologics.push(new MedicalHistory("11", this.medicalHistoryOptions[11].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[9].name,neurologics));

    var cardio: Array<MedicalHistory> = new Array<MedicalHistory>();
    cardio.push(new MedicalHistory("12", this.medicalHistoryOptions[12].name));
    cardio.push(new MedicalHistory("42", this.medicalHistoryOptions[42].name));
    cardio.push(new MedicalHistory("13", this.medicalHistoryOptions[13].name));
    cardio.push(new MedicalHistory("14", this.medicalHistoryOptions[14].name));
    cardio.push(new MedicalHistory("15", this.medicalHistoryOptions[15].name));
    cardio.push(new MedicalHistory("16", this.medicalHistoryOptions[16].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[10].name,cardio));

    var respiratorias: Array<MedicalHistory> = new Array<MedicalHistory>();
    respiratorias.push(new MedicalHistory("17", this.medicalHistoryOptions[17].name));
    respiratorias.push(new MedicalHistory("43", this.medicalHistoryOptions[43].name));
    respiratorias.push(new MedicalHistory("18", this.medicalHistoryOptions[18].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[11].name,respiratorias));

    var gastro: Array<MedicalHistory> = new Array<MedicalHistory>();
    gastro.push(new MedicalHistory("44", this.medicalHistoryOptions[44].name));
    gastro.push(new MedicalHistory("45", this.medicalHistoryOptions[45].name));
    gastro.push(new MedicalHistory("19", this.medicalHistoryOptions[19].name));
    gastro.push(new MedicalHistory("20", this.medicalHistoryOptions[20].name));
    gastro.push(new MedicalHistory("21", this.medicalHistoryOptions[21].name));
    gastro.push(new MedicalHistory("22", this.medicalHistoryOptions[22].name));
    gastro.push(new MedicalHistory("23", this.medicalHistoryOptions[23].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[12].name,gastro));

    var hema: Array<MedicalHistory> = new Array<MedicalHistory>();
    hema.push(new MedicalHistory("24", this.medicalHistoryOptions[24].name));
    hema.push(new MedicalHistory("25", this.medicalHistoryOptions[25].name));
    hema.push(new MedicalHistory("26", this.medicalHistoryOptions[26].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[13].name,hema));

    var infect: Array<MedicalHistory> = new Array<MedicalHistory>();
    infect.push(new MedicalHistory("27", this.medicalHistoryOptions[27].name));
    infect.push(new MedicalHistory("28", this.medicalHistoryOptions[28].name));
    infect.push(new MedicalHistory("46", this.medicalHistoryOptions[46].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[14].name,infect));

    var onco: Array<MedicalHistory> = new Array<MedicalHistory>();
    onco.push(new MedicalHistory("29", this.medicalHistoryOptions[29].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[15].name,onco));

    var heredi: Array<MedicalHistory> = new Array<MedicalHistory>();
    heredi.push(new MedicalHistory("30", this.medicalHistoryOptions[30].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[16].name,heredi));

    var surgeries: Array<MedicalHistory> = new Array<MedicalHistory>();
    surgeries.push(new MedicalHistory("31", this.medicalHistoryOptions[31].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[17].name,surgeries));

    var specialMedication: Array<MedicalHistory> = new Array<MedicalHistory>();
    specialMedication.push(new MedicalHistory("32", this.medicalHistoryOptions[32].name));
    specialMedication.push(new MedicalHistory("33", this.medicalHistoryOptions[33].name));
    specialMedication.push(new MedicalHistory("34", this.medicalHistoryOptions[34].name));
    this.categories.push(new MedicalHistoryCategory(this.medicalHistoryCategoryNames[18].name,specialMedication));

    //Another map with history by id
    this.categories.forEach((category) => {
      category.records.forEach(medicalHistory => {
        this.medicalHistoriesById.set(medicalHistory.id, medicalHistory);
        this.histories.push(medicalHistory);
      });
    });
  }

  /**
   * set no background history and set all other to false
   */
  public setNoBackgroundHistory() {
    this.histories.forEach((history) => {
      if(!history.equals(this.noBackgroundHistory)) {
        history.selected = false;
      }
    });
  }

  /**
   * history is valid if at least one record is selected
   */
  public validate() {
    var valid = false;
    this.histories.forEach((history) => {
      if(history.selected === true) {
        valid = true;
        return;
      }
    });
    this.isValid = valid;
  }

  public getSelectedHistories() {
    var selectedHistories = this.histories.filter(history => history.selected);
    var selectedIds = [];

    selectedHistories.forEach((medicalHistory:MedicalHistory) => {
      selectedIds.push(medicalHistory.id);
    });

    return selectedIds;
  }
  
  private fillMedicalHistoryOptions(){
    switch (this.localizationService.getLocale()) {
      case "pt":
      case "pt-br":
        this.medicalHistoryCategoryNames = [
          {id: "0", name: "Eu não apresento nenhum antecedente"},
          {id: "1", name: "Hipertensão arterial"},
          {id: "2", name: "Diabetes"},
          {id: "3", name: "Baixo peso"},
          {id: "4", name: "Excesso de peso"},
          {id: "5", name: "Hipo/Hipertiroidismo"},
          {id: "6", name: "Hábitos"},
          {id: "7", name: "Alergia a medicamentos"},
          {id: "8", name: "Doenças durante a gravidez"},
          {id: "9", name: "Doenças neurológicas"},
          {id: "10", name: "Doenças cardiovasculares"},
          {id: "11", name: "Doenças respiratórias"},
          {id: "12", name: "Doenças gastrointestinais"},
          {id: "13", name: "Doenças hematológicas"},
          {id: "14", name: "Doenças infecciosas"},
          {id: "15", name: "Doenças Oncológicas"},
          {id: "16", name: "Doenças congênitas/hereditárias"},
          {id: "17", name: "Cirurgias prévias"},
          {id: "18", name: "Medicação especial"}
        ]
        this.medicalHistoryOptions = [
          {id: "0", name: "Eu não apresento nenhum antecedente"},
          {id: "1", name: "Hipertensão arterial"},
          {id: "2", name: "Diabetes"},
          {id: "3", name: "Hipo/Hipertiroidismo" },
          {id: "4", name: "Tabagismo"},
          {id: "5", name: "Alcoolismo"},
          {id: "6", name: "Drogas"},
          {id: "7", name: "Alergia a medicamentos"},
          {id: "8", name: "Acidente vascular cerebral"},
          {id: "9", name: "Outras convulsões/Epilepsia"},
          {id: "10", name: "Enxaqueca/Cefaleia"},
          {id: "11", name: "Comprometimento Cognitivo"},
          {id: "12", name: "Enfarte"},
          {id: "13", name: "Arritmias"},
          {id: "14", name: "Colocação de stent"},
          {id: "15", name: "Cirurgia cardiovascular"},
          {id: "16", name: "Marcapasso"},
          {id: "17", name: "Asma"},
          {id: "18", name: "Enfisema pulmonar"},
          {id: "19", name: "Hemorragia digestiva"},
          {id: "20", name: "Gastrite/Úlcera gástrica"},
          {id: "21", name: "Hepatite"},
          {id: "22", name: "Insuficiência hepática"},
          {id: "23", name: "Cirrose"},
          {id: "24", name: "Distúrbios da coagulação"},
          {id: "25", name: "Linfoma/Leucemia"},
          {id: "26", name: "Neutropenia"},
          {id: "27", name: "Doenças sexualmente transmissíveis"},
          {id: "28", name: "Tuberculose"},
          {id: "29", name: "Doenças oncológicas"},
          {id: "30", name: "Doenças congênitas/hereditárias"},
          {id: "31", name: "Cirurgias prévias"},
          {id: "32", name: "Anticoagulação"},
          {id: "33", name: "Quimioterapia"},
          {id: "34", name: "Corticóides"},
          {id: "35", name: "Baixo peso"},
          {id: "36", name: "Excesso de peso"},
          {id: "37", name: "Recém-nascido prematuro"},
          {id: "38", name: "Doenças durante a gravidez"},
          {id: "39", name: "Doença genética"},
          {id: "40", name: "Convulsões febris"},
          {id: "41", name: "Atraso maturativo"},
          {id: "42", name: "Cardiopatia congênita"},
          {id: "43", name: "Broncoespasmo de repetição"},
          {id: "44", name: "Alergia ao leite de vaca"},
          {id: "45", name: "Doença celíaca" },
          {id: "46", name: "Imunodeficiências" },
        ]
        break;
      case 'en':
      case 'en-us':
        this.medicalHistoryCategoryNames = [
          {"id": "0", name: "No pathological history"},
          {"id": "1", name: "Arterial hypertension"},
          {"id": "2", name: "Diabetes"},
          {"id": "3", name: "Underweight"},
          {"id": "4", name: "Overweight"},
          {"id": "5", name: "Hypo/Hyperthyroidism"},
          {"id": "6", name: "Habits"},
          {"id": "7", name: "Medication allergy"},
          {"id": "8", name: "Diseases during pregnancy"},
          {"id": "9", name: "Neurological disease"},
          {"id": "10", name: "Cardiovascular diseases"},
          {"id": "11", name: "Respiratory diseases"},
          {"id": "12", name: "Gastrointestinal diseases"},
          {"id": "13", name: "Hematologic Diseases"},
          {"id": "14", name: "Infectious Diseases"},
          {"id": "15", name: "Oncological diseases"},
          {"id": "16", name: "Congenital/Hereditary Diseases"},
          {"id": "17", name: "Previous surgeries"},
          {"id": "18", name: "Special medication"}
        ]
        this.medicalHistoryOptions = [
          {id: "0", name: "No pathological history"},
          {id: "1", name: "Arterial hypertension"},
          {id: "2", name: "Diabetes"},
          {id: "3", name: "Hypo/Hyperthyroidism" },
          {id: "4", name: "Smoking"},
          {id: "5", name: "Alcoholism"},
          {id: "6", name: "Drugs"},
          {id: "7", name: "Medication allergy"},
          {id: "8", name: "Cerebrovascular accident"},
          {id: "9", name: "Other seizure/Epilepsy"},
          {id: "10", name: "Migraine/Headache"},
          {id: "11", name: "Cognitive impairment"},
          {id: "12", name: "Heart attack"},
          {id: "13", name: "Arrhythmias"},
          {id: "14", name: "Stent implantation"},
          {id: "15", name: "Cardiovascular surgery"},
          {id: "16", name: "Pacemaker"},
          {id: "17", name: "Asthma"},
          {id: "18", name: "Pulmonary emphysema"},
          {id: "19", name: "Gastrointestinal Bleeding"},
          {id: "20", name: "Gastritis/Gastric ulcer"},
          {id: "21", name: "Hepatitis"},
          {id: "22", name: "Liver Failure"},
          {id: "23", name: "Cirrhosis"},
          {id: "24", name: "Coagulation disorders"},
          {id: "25", name: "Leukemia/Lymphoma"},
          {id: "26", name: "Neutropenia"},
          {id: "27", name: "Sexually transmitted diseases"},
          {id: "28", name: "Tuberculosis"},
          {id: "29", name: "Oncological diseases"},
          {id: "30", name: "Congenital/Hereditary Diseases"},
          {id: "31", name: "Previous surgeries"},
          {id: "32", name: "Anticoagulation"},
          {id: "33", name: "Chemotherapy"},
          {id: "34", name: "Corticosteroids"},
          {id: "35", name: "Underweight"},
          {id: "36", name: "Overweight"},
          {id: "37", name: "Premature infant"},
          {id: "38", name: "Infections during pregnancy"},
          {id: "39", name: "Genetic disease"},
          {id: "40", name: "Febrile seizure"},
          {id: "41", name: "Maturational delay"},
          {id: "42", name: "Congenital heart disease"},
          {id: "43", name: "Repeated bronchospasms"},
          {id: "44", name: "Cow's milk allergy"},
          {id: "45", name: "Celiac disease" },
          {id: "46", name: "Immunodeficiency disease" },
        ]
        break;
      default:
        this.medicalHistoryCategoryNames = [
          {"id": "0", name: "No presento antecedentes patológicos"},
          {"id": "1", name: "Hipertensión arterial"},
          {"id": "2", name: "Diabetes"},
          {"id": "3", name: "Bajo peso"},
          {"id": "4", name: "Sobrepeso"},
          {"id": "5", name: "Hipo/Hipertiroidismo"},
          {"id": "6", name: "Hábitos"},
          {"id": "7", name: "Alergia a medicamentos"},
          {"id": "8", name: "Enf. Durante el embarazo"},
          {"id": "9", name: "Enf. Neurológicas"},
          {"id": "10", name: "Enf. Cardiovasculares"},
          {"id": "11", name: "Enf. Respiratorias"},
          {"id": "12", name: "Enf. Gastrointestinales"},
          {"id": "13", name: "Enf. Hematológicas"},
          {"id": "14", name: "Enf. Infectológicas"},
          {"id": "15", name: "Enf. Oncológicas"},
          {"id": "16", name: "Enf. Congénitas/Hereditarias"},
          {"id": "17", name: "Cirugías previas"},
          {"id": "18", name: "Medicación especial"}
        ]
        this.medicalHistoryOptions = [
          {id: "0", name: "No presento antecedentes patológicos"},
          {id: "1", name: "Hipertensión arterial"},
          {id: "2", name: "Diabetes"},
          {id: "3", name: "Hipo/Hipertiroidismo" },
          {id: "4", name: "Tabaquismo"},
          {id: "5", name: "Alcoholismo"},
          {id: "6", name: "Drogas"},
          {id: "7", name: "Alergia a medicamentos"},
          {id: "8", name: "Accidente cerebrovascular"},
          {id: "9", name: "Otras Convulsiones/Epilepsia"},
          {id: "10", name: "Migraña/Cefalea"},
          {id: "11", name: "Deterioro cognitivo"},
          {id: "12", name: "Infarto"},
          {id: "13", name: "Arritmias"},
          {id: "14", name: "Colocación de stents"},
          {id: "15", name: "Cirugía cardiovascular"},
          {id: "16", name: "Marcapasos"},
          {id: "17", name: "Asma"},
          {id: "18", name: "Enfisema pulmonar"},
          {id: "19", name: "Hemorragia digestiva"},
          {id: "20", name: "Gastritis/Úlcera gástrica"},
          {id: "21", name: "Hepatitis"},
          {id: "22", name: "Insuficiencia hepática"},
          {id: "23", name: "Cirrosis"},
          {id: "24", name: "Trastornos de la coagulación"},
          {id: "25", name: "Linfoma/Leucemia"},
          {id: "26", name: "Neutropenia"},
          {id: "27", name: "Enfermedades de transmición sexual"},
          {id: "28", name: "Tuberculosis"},
          {id: "29", name: "Enf. Oncológicas"},
          {id: "30", name: "Enfermedades Congénitas/Hereditarias"},
          {id: "31", name: "Cirugías previas"},
          {id: "32", name: "Anticoagulación"},
          {id: "33", name: "Quimioterapia"},
          {id: "34", name: "Corticoides"},
          {id: "35", name: "Bajo peso"},
          {id: "36", name: "Sobrepeso"},
          {id: "37", name: "Recién nacido prematuro"},
          {id: "38", name: "Infecciones durante el embarazo"},
          {id: "39", name: "Enfermedad genética"},
          {id: "40", name: "Convulsiones febriles"},
          {id: "41", name: "Retraso madurativo"},
          {id: "42", name: "Cardiopatía congénita"},
          {id: "43", name: "Broncoespasmos a repetición"},
          {id: "44", name: "Alergia a la leche de vaca"},
          {id: "45", name: "Enfermedad celíaca" },
          {id: "46", name: "Inmunodeficiencias" },
        ]
        break;
    }
  }

}
