import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TermsAndConditionsService } from '../../services/terms-and-conditions.service';
import { UserDataService } from '../../services/user-data.service';
import { ProviderService } from '../../services/provider.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  path;
  providerName = "Llamando al Doctor"
  accept:boolean = false;

  constructor(public router: Router,
    public termsService: TermsAndConditionsService,
    protected providerService: ProviderService,
    protected userDataService: UserDataService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.userDataService.getPatient().then(patient => {
      if(patient.provider)
      {
        this.path = this.sanitizer.bypassSecurityTrustResourceUrl(this.providerService.getTCUrl(patient.provider.id));
        this.providerName = patient.provider.name;
      }
    })
  }

  continue() {
    if(this.accept)
    {
      this.termsService.accept();
      if(this.userDataService.hasPermissions())
        this.router.navigate(['/warning']);
      else
        this.router.navigate(['/ask-permissions']);
    }
  }

}
