import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http'; // change usage with HttpClient
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { MatDialogModule } from '@angular/material/dialog';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';

import { MatToolbarModule, 
          MatInputModule,
          MatIconModule,
          MatFormFieldModule,
          MatSelectModule,
          MatOptionModule,
          MatButtonModule,
          MatCheckboxModule,
          MatDatepickerModule,
          MatNativeDateModule,
          MatSidenavModule,
          MatListModule,
          MatExpansionModule,
          MatCardModule
        } from '@angular/material';

import { AppComponent, SafePipe } from './app.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PatientValidationComponent } from './components/patient-validation/patient-validation.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { RegistrationFirstStepComponent } from './components/registration-first-step/registration-first-step.component';
import { RegistrationSecondStepComponent } from './components/registration-second-step/registration-second-step.component';
import { PatientReasonComponent } from './components/patient-reason/patient-reason.component';
import { MedicalRecordsComponent } from './components/medical-records/medical-records.component';
import { MedicalHistoryCategoryComponent } from './components/medical-history-category/medical-history-category.component';
import { WarningComponent } from './components/warning/warning.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CallingComponent } from './components/calling/calling.component';
import { PatientRateCallComponent } from './components/patient-rate-call/patient-rate-call.component';
import { AskPermissionsComponent } from './components/ask-permissions/ask-permissions.component';
import { SearchComponent } from './components/search/search.component';
import { CoronavirusInfoComponent } from './components/coronavirus-info/coronavirus-info.component';
import { ModalComponent } from './components/modal/modal.component';
import { AppointmentTimeComponent } from './dialogs/appointment-time/appointment-time.component';

/* SERVICES */
import { RequestService } from './services/request.service';
import { AffiliatesService } from './services/affiliates.service';
import { CallService } from './services/call.service';
import { DoctorService } from './services/doctor.service';
import { PatientService } from './services/patient.service';
import { LocalizationService } from './services/localization.service';
import { AuthService } from './services/auth.service';
import { ProviderService } from './services/provider.service';
import { RegistrationDataService } from './services/registration-data.service';
import { CallByPatientDataService } from './services/call-by-patient-data.service';
import { AuthorizedService } from './services/authorized.service';
import { RouteFlowCheckerService } from './services/route-flow-checker.service';
import { FillerNavService } from './services/filler-nav.service';
import { AppointmentService } from './services/appointment.service';
import { TokenExpiredService } from './token-expired.service';
import { AmplitudeService } from './services/amplitude.service';

/* DIALOGS */
import { NoAffiliateComponent } from './dialogs/no-affiliate/no-affiliate.component';
import { SpecialitySelectionComponent } from './components/speciality-selection/speciality-selection.component';
import { MenuComponent } from './components/menu/menu.component';
import { RecallComponent } from './dialogs/recall/recall.component';
import { TimeoutMessageComponent } from './dialogs/timeout-message/timeout-message.component';
import { NotAvailableMessageComponent } from './dialogs/not-available-message/not-available-message.component';
import { NotAllowedNavigatorComponent } from './dialogs/not-allowed-navigator/not-allowed-navigator.component';
import { MessageComponent } from './dialogs/message/message.component';

const appRoutes: Routes = [
  { path: '', component: LoadingComponent },
  { path: 'validation', component: PatientValidationComponent, canActivate: [RouteFlowCheckerService] },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'speciality-selection', component: SpecialitySelectionComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'registration-first', component: RegistrationFirstStepComponent, canActivate: [RouteFlowCheckerService] },
  { path: 'registration-second', component: RegistrationSecondStepComponent, canActivate: [RouteFlowCheckerService] },
  { path: 'warning', component: WarningComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'patient-reason', component: PatientReasonComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'medical-records', component: MedicalRecordsComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'patient-rate-call', component: PatientRateCallComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'ask-permissions', component: AskPermissionsComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'calling', component: CallingComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'search', component: SearchComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'coronavirus-info', component: CoronavirusInfoComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'appointment-end', component: AppointmentEndComponent, canActivate: [AuthorizedService,RouteFlowCheckerService] },
  { path: 'logout', component: LogoutComponent }

];

/* DIRECTIVES */
import { EqualValidator } from './equal-validator.directive';
import { TranslatePipe } from './translate.pipe';
import { Autosize } from './autosize.directive';
import { LoadingComponent } from './components/loading/loading.component';

import {Ng2TelInputModule} from 'ng2-tel-input';
import { SecurePipe } from './secure.pipe';
import { EpochPipe } from './epoch.pipe';
import { EpochToYearsOldPipe } from './epoch-to-years-old.pipe';
import { AppointmentEndComponent } from './components/appointment-end/appointment-end.component';
import { LogoutComponent } from './components/logout/logout.component';

export function setupTranslateFactory(service: LocalizationService): Function {
  var locale = (window.navigator.languages || window.navigator["userLanguage"]);
  return () => service.use(locale);
}

@NgModule({
  declarations: [
    AppComponent,
    PatientValidationComponent,
    TermsAndConditionsComponent,
    RegistrationFirstStepComponent,
    RegistrationSecondStepComponent,
    NoAffiliateComponent,
    SpecialitySelectionComponent,
    PatientReasonComponent,
    MenuComponent,
    MedicalRecordsComponent,
    MedicalHistoryCategoryComponent,
    EqualValidator,
    TranslatePipe,
    Autosize,
    WarningComponent,
    ProfileComponent,
    CallingComponent,
    PatientRateCallComponent,
    AskPermissionsComponent,
    LoadingComponent,
    SecurePipe,
    EpochPipe,
    EpochToYearsOldPipe,
    SearchComponent,
    RecallComponent,
    TimeoutMessageComponent,
    NotAvailableMessageComponent,
    SafePipe,
    NotAllowedNavigatorComponent,
    CoronavirusInfoComponent,
    ModalComponent,
    AppointmentTimeComponent,
    MessageComponent,
    AppointmentEndComponent,
    LogoutComponent
  ],
  imports: [
    HttpModule, // replace with HttpClient
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatInputModule, 
    MatIconModule, 
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatCardModule,
    Ng2TelInputModule,
    DeviceDetectorModule.forRoot(),
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    RouterModule.forRoot(
      appRoutes,
      //{ enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [MatDialogModule],
  entryComponents: [
    NoAffiliateComponent,
    RecallComponent,
    TimeoutMessageComponent,
    NotAvailableMessageComponent,
    NotAllowedNavigatorComponent,
    ModalComponent,
    AppointmentTimeComponent,
    MessageComponent
  ],
  providers: [
    RequestService,
    AffiliatesService,
    CallService,
    DoctorService,
    PatientService,
    LocalizationService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ LocalizationService ],
      multi: true
    },
    AuthService,
    ProviderService,
    RegistrationDataService,
    CallByPatientDataService,
    AuthorizedService,
    RouteFlowCheckerService,
    FillerNavService,
    CookieService,
    AppointmentService,
    TokenExpiredService,
    AmplitudeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
