import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from './services/localization.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  
  constructor(private localization:LocalizationService) {}

  transform(key:any): any {
    return this.localization.translations[key] || key;
  }

}
