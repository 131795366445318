import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FamilyGroup } from '../../models/familyGroup.model';
import { UserDataService } from '../../services/user-data.service';
import { PatientService } from '../../services/patient.service';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { CallByPatientDataService } from '../../services/call-by-patient-data.service';
import { FillerNavService } from 'src/app/services/filler-nav.service';
import { MatDialog } from '@angular/material';
import { ModalComponent } from '../modal/modal.component';
import { AmplitudeService } from '../../services/amplitude.service';


@Component({
  selector: 'app-patient-reason',
  templateUrl: './patient-reason.component.html',
  styleUrls: ['./patient-reason.component.scss']
})
export class PatientReasonComponent implements OnInit {
  @Output() openedChange: EventEmitter<boolean>

  familyGroup:FamilyGroup;
  patientReason:string = "";
  selected;
  motive;
  appointment:boolean = false;

  fillerNav;

  constructor(protected router: Router,
    public userDataService: UserDataService,
    public callByPatientDataService: CallByPatientDataService,
    public patientService: PatientService,
    public dialog: MatDialog,
    protected fillerNavService:FillerNavService,
    public amplitudeService: AmplitudeService) {
    this.fillerNav = this.fillerNavService.getFiller();
    this.familyGroup  = new FamilyGroup();
    this.familyGroup.members = [];
  }

  ngOnInit() {
    this.userDataService.getPatient().then(patient => {
      if(patient)
      {
        this.patientService.familyGroup(patient.familyGroup).then((familyGroup)=> {
          this.familyGroup = familyGroup;
          this.selected = this.familyGroup.members[0];
          this.appointment = this.userDataService.hasAppointment();
          if(patient.provider.supportsPrescription && !this.appointment)
            this.openDialog(this.selected.name,patient.email);
        });
      }
    })
    var motive = this.callByPatientDataService.motive;
    if(motive)
    {
      this.motive = motive;
    }
  }

  openDialog(patientName,patientEmail){
    this.dialog.open(ModalComponent, {
      data: {
        textHeader:"¡Aclaración!",
        textHtmlKey:"prescriptionMessage",
        patientName:patientName,
        patientEmail:patientEmail
      },
    });
  }

  selectorChange(event:any){
    if(event && this.selected)
    {
      this.selected = null;
    }
  }

  continue(reason): void {
    if(reason.valid && this.selected)
    {
      this.callByPatientDataService.motive = this.motive;
      this.callByPatientDataService.caller = this.selected;
      this.amplitudeService.showEvent('paciente_selecciono_motivo', { callerName: this.selected.cleanName });
      this.router.navigate(['/medical-records']);
    }
  }

}
