import { Injectable } from '@angular/core';
import { Speciality } from '../models/speciality.models';
import { Patient } from '../models/patient.models';
import { Call } from '../models/call.models';
import { Doctor } from '../models/doctor.models';

@Injectable({
  providedIn: 'root'
})
export class CallByPatientDataService {
  
  public motive:string;
  public speciality:Speciality;
  public caller:Patient;
  public call:Call;
  public callingOrInCall:boolean;
  public doctorToCall:Doctor;

  constructor() { }

  public finished() {
    this.motive = '';
    this.speciality = undefined;
    this.caller = undefined;
    this.call = undefined;
    this.callingOrInCall = false;
    this.doctorToCall = undefined;
  }

}
