import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { FillerNavService } from 'src/app/services/filler-nav.service';
import { CallByPatientDataService } from 'src/app/services/call-by-patient-data.service';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-coronavirus-info',
  templateUrl: './coronavirus-info.component.html',
  styleUrls: ['./coronavirus-info.component.scss']
})
export class CoronavirusInfoComponent implements OnInit {

  fillerNav;
  iframeUrl;

  constructor(protected router: Router,
    protected callByPatientData:CallByPatientDataService,
    protected fillerNavService: FillerNavService,
    protected localizationService:LocalizationService) {
    this.fillerNav = this.fillerNavService.getFiller();
    this.iframeUrl = this.getUrl()
  }

  ngOnInit() {
  }

  getUrl() {
    switch (this.localizationService.getLocale()) {
      case "en":
      case "en-us":
      return "https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public"

      default:
      return "https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public"
    }
  }
  continue() {
    this.router.navigate(['/patient-reason']);
  }
}
