import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-timeout-message',
  templateUrl: './timeout-message.component.html',
  styleUrls: ['./timeout-message.component.css']
})
export class TimeoutMessageComponent implements OnInit {

  isAppointment

  constructor(public dialogRef: MatDialogRef<TimeoutMessageComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.isAppointment = this.data.isAppointment
  }

  close(): void {
    this.dialogRef.close();
  }

}
