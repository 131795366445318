import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Patient } from '../models/patient.models';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  url = '/patient';
  familyGroupUrl = '/familygroup';

  constructor(protected requestService: RequestService) {  }

  getMe(){
    return this.requestService.makeRequest({method:"GET"},'/me')
    .then((obj) => {
      return obj.json() as Patient;
    })
  }

  createPS(affiliate){
    let options={
      method:"POST",
      body:JSON.stringify(affiliate)
    }
    return this.requestService.makeRequest(options,this.url)
    .then((obj) => {
      return obj.json() as Patient;
    })
  }

  create(affiliate){
    let options={
      method:"POST",
      body:JSON.stringify(affiliate)
    }
    return this.requestService.makeRequest(options,this.url)
    .then((obj) => {
      return obj.json() as Patient;
    })
  }

  update(affiliate){
    let options={
      method:"PUT",
      body:JSON.stringify(affiliate)
    }
    return this.requestService.makeRequest(options,this.url+"/"+affiliate.id)
    .then((obj) => {
      return obj.json() as Patient;
    })
  }

  familyGroup(id): Promise<any> {
    return this.requestService.makeRequest({method:"GET"},this.familyGroupUrl+"/"+id)
    .then((obj) => {
      return obj.json() as Patient[];
    })
  }
}
