import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../services/user-data.service';
import { Router } from '@angular/router';
import { Affiliate } from '../../models/affiliate.models';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.css']
})
export class WarningComponent implements OnInit {

  phone:string;
  providerName:string;
  emergencyPhone:string;

  constructor(protected router: Router,
    protected userDataService: UserDataService,
    protected localizationService: LocalizationService) { }

  ngOnInit() {
    this.userDataService.getPatient().then(patient => {
      if(patient)
      {
        this.phone = patient.provider.emergencyCall;
        this.providerName = patient.provider.name;
      }
    })
    this.emergencyPhone = this.getEmergencyPhone();
  }

  continue() {
    if(this.userDataService.hasAppointment())
      this.router.navigate(['/patient-reason'])
    else
      this.router.navigate(['/speciality-selection']);
  }

  getEmergencyPhone() {
    switch (this.localizationService.getLocale()) {
      case 'pt':
      case 'pt-br':
        return '192';
      case 'es-co':
        return '123';
      case 'en':
      case 'en-us':
        return '911';
      default:
        return '107';
    }
  }

}
